import React from 'react';
import { Alert, AlertTitle, Box, Button, Card, Divider, Modal, Typography } from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import { axiosInstance } from 'src/utils/axios';
import { apiRoutes } from 'src/routes/api';
import { useSnackbar } from 'notistack';

// type StateProps = {
//   payment_modal?: boolean;
//   no_sub_modal?: boolean;
// };

type ModalCancelSubscriptionProps = {
  open: boolean;
  handleCloseModal: () => void;
  external_id: string;
};

export const ModalCancelSubscription = ({
  open,
  handleCloseModal,
  external_id,
}: ModalCancelSubscriptionProps) => {
  const { enqueueSnackbar } = useSnackbar();

  const cancelSubscription = async () => {
    try {
      await axiosInstance.post(`/payment/cancel-subscription/${external_id}`);
      enqueueSnackbar('Assinatura cancelada com sucesso!', { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }

    handleCloseModal();
    window.location.reload();
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Card
        sx={{
          minHeight: '400px',
          maxWidth: '700px',
          padding: '15px',
        }}
      >
        <Typography sx={{ marginBottom: '10px' }}>Cancelamento de Assinatura</Typography>
        <Divider />
        <Alert severity="warning" sx={{ marginTop: '10px' }}>
          <AlertTitle>Atenção</AlertTitle>
          Tem certeza que deseja cancelar a sua assinatura?
        </Alert>
        <Card
          sx={{
            backgroundColor: '#F4F6F8',
            border: '1px solid rgba(145, 158, 171, 0.24)',
            padding: '10px',
            marginTop: '10px',
          }}
        >
          Ao cancelar antes de completar 7 dias da confirmação de cadastro, você não receberá a
          cobrança em seu cartão de crédito Em caso de dúvidas sobre cancelamento, entre em contato
          com o suporte ao cliente.
        </Card>
        <Typography variant="h5" sx={{ textAlign: 'center', marginTop: '10px' }}>
          Você confirma o cancelamento da assinatura?
        </Typography>
        <Typography sx={{ textAlign: 'center', marginTop: '10px' }}>
          Se confirmada a ação, você não poderá mais acessar as principais funcionalidades do
          sistema. Todos os seus projetos e cálculos ficarão salvos em nossa base de dados. E você
          retomará o acesso a esses dados e funcionalidades quando assinar novamente um novo plano.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '20px',
            marginTop: '15px',
          }}
        >
          <Button variant="outlined" onClick={handleCloseModal}>
            NÃO
          </Button>
          <Button variant="contained" onClick={cancelSubscription}>
            SIM
          </Button>
        </Box>
      </Card>
    </Modal>
  );
};
