import { StyleSheet, Font } from '@react-pdf/renderer';
import palette from 'src/theme/palette';

import MontserratRegular from './fonts/Montserrat-Regular.ttf';
import MontserratMedium from './fonts/Montserrat-Medium.ttf';
import MontserratSemiBold from './fonts/Montserrat-SemiBold.ttf';
import MontserratBold from './fonts/Montserrat-Bold.ttf';

Font.register({
  family: 'Montserrat',
  format: 'opentype',
  fonts: [
    { src: MontserratRegular },
    { src: MontserratMedium, fontWeight: 500 },
    { src: MontserratSemiBold, fontWeight: 600 },
    { src: MontserratBold, fontWeight: 'bold' },
  ],
});

const setStyles = (profileColor?: string) => {
  const styles = StyleSheet.create({
    page: {
      display: 'flex',
      opacity: 1,
      alignItems: 'center',
      padding: 25,
      backgroundColor: '#fff',
    },
    container: {
      width: '100%',
      height: '80%',
      padding: '0 13%',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      fontFamily: 'Montserrat',
    },

    // Header
    header: {
      width: '100%',
      padding: '5% 13% 0',
      borderBottom: `1 solid ${palette.light.grey[300]}`,
      flexDirection: 'row',
    },
    logo: {
      paddingBottom: '5px',
    },
    moduleContainer: {
      width: '50%',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    logoModuleContainer: {
      width: '20px',
      height: '20px',

      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '20%',
    },
    logoModuleContainerLight: {
      backgroundColor: palette.light.primary.light,
    },

    logoModuleContainerDark: {
      backgroundColor: palette.light.primary.dark,
    },
    logoModule: {
      width: '9px',
    },
    logoContainer: {
      width: '50%',
    },
    moduleText: {
      marginLeft: '10px',
      fontFamily: 'Montserrat',
      fontSize: 10,
      fontWeight: 550,
      letterSpacing: '0px',
      color: palette.dark.grey[800],
    },

    // Title
    titleContainer: {
      marginTop: '18px',
      borderBottom: `1 solid ${palette.light.grey[300]}`,
      width: '100%',
    },
    titleContainerMaterials: {
      marginTop: '18px',
      width: '100%',
    },
    title: {
      fontSize: 14,
      fontWeight: 600,
      marginBottom: '10px',
      letterSpacing: '0px',
      color: palette.dark.grey[800],
    },

    // article
    article: {
      width: '100%',
      padding: '10px 0',
      borderBottom: `1 solid ${palette.light.grey[300]}`,
    },
    articleBottom: {
      width: '100%',
      padding: '10px 0',
    },
    articleTitle: {
      fontSize: 9,
      fontWeight: 600,
      letterSpacing: '1.2px',
      color: palette.dark.grey[600],
      marginBottom: '12px',
      textTransform: 'uppercase',
    },
    section: {
      marginTop: '8px',
    },
    ParameterContainer: {
      flexDirection: 'row',
      marginBottom: '7px',
    },
    ParameterName: {
      fontSize: 8,
      fontWeight: 500,
      color: palette.dark.grey[600],
    },
    ParameterData: {
      fontSize: 8,
      fontWeight: 500,
      marginLeft: '8px',
    },

    sectionSide: {
      marginBottom: '8px',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    ParameterContainerSide: {
      width: '50%',
      flexDirection: 'row',
      marginBottom: '7px',
    },

    articleResult: {
      width: '100%',
      padding: '10px 0',
    },
    ParameterContainerResult: {
      flexDirection: 'row',
      marginBottom: '7px',
      alignItems: 'center',
      marginTop: '14px',
    },
    ParameterNameResult: {
      fontSize: 10,
      fontWeight: 600,
      color: palette.dark.grey[900],
    },
    ParameterDataResult: {
      fontSize: 10,
      fontWeight: 600,
      marginLeft: '8px',
      color: palette.light.primary.main,
      backgroundColor: palette.light.primary.lighter,
      padding: '6px 10px',
      border: `1px solid ${palette.light.primary.main}`,
      borderRadius: '5px',
    },
    ParameterDataResultNa: {
      fontSize: 10,
      fontWeight: 600,
      marginLeft: '8px',
      color: palette.light.error.main,
      backgroundColor: palette.light.error.lighter,
      padding: '6px 10px',
      border: `1px solid ${palette.light.error.main}`,
      borderRadius: '5px',
    },
    ParameterDataResultWarning: {
      fontSize: 10,
      fontWeight: 600,
      marginLeft: '8px',
      color: palette.light.warning.main,
      backgroundColor: palette.light.warning.lighter,
      padding: '6px 10px',
      border: `1px solid ${palette.light.warning.main}`,
      borderRadius: '5px',
    },

    // FOOTER
    footer: {
      position: 'absolute',
      width: '100%',
      padding: '10px 13%',
      borderTop: `1 solid ${palette.light.grey[300]}`,
      flexDirection: 'row',
      height: '8%',
      alignSelf: 'flex-end',
      bottom: 0,
      alignItems: 'flex-start',
      justifyContent: 'space-between',
    },
    logoFooter: {
      height: '25px',
      width: '93px',
    },
    logoContainerFooter: {
      height: '25px',
      width: '93px',
    },
    textFooter: {
      marginTop: '10px',
      fontSize: '8px',
      color: palette.dark.grey[700],
    },

    // Chart
    chartContainer: {},
    chart: {},

    articleText: {
      fontSize: 10,
      fontWeight: 600,
      color: palette.dark.grey[600],
      marginBottom: '12px',
    },

    // Status
    statusContainer: {
      backgroundColor: '#D9ECC4',
      borderRadius: '5px',
      padding: '12px 18px',
      flexDirection: 'row',
    },
    statusContainerError: {
      backgroundColor: '#FFE7D9',
      borderRadius: '5px',
      padding: '12px 18px',
      flexDirection: 'row',
    },
    statusLogoContainer: {},
    statusLogo: {
      width: '12px',
      height: '12px',
    },
    statusTextContainer: {
      marginLeft: '12px',
    },
    statusTitle: {
      fontSize: 10,
      fontWeight: 600,
      color: '#08660D',
      marginBottom: '10px',
    },
    statusTitleError: {
      fontSize: 10,
      fontWeight: 600,
      color: '#7A0C2E',
      marginBottom: '10px',
    },
    statusDescription: {
      fontSize: 10,
      fontWeight: 400,
      color: '#08660D',
    },
    statusDescriptionError: {
      fontSize: 10,
      fontWeight: 400,
      color: '#7A0C2E',
    },
    technicalFeedback: {
      fontSize: 10,
      fontWeight: 400,
      color: palette.dark.grey[800],
    },

    // Signature Area

    sinatureContainer: {
      width: '100%',
      marginTop: '50px',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'flex-end',
    },
    signatureArea: {
      width: '40%',
    },
    signatureLine: {
      marginBottom: '5px',
      borderTop: '1px solid black',
    },
    signatureText: {
      marginBottom: '3px',
      fontSize: 8,
    },
    signatureTextData: {
      fontWeight: 600,
    },

    // itemTable

    tableContainer: {
      marginTop: '10px',
      width: '100%',
      borderRadius: '40px',
    },
    tableHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      backgroundColor: palette.dark.grey[200],
      color: '#637381',
      paddingRight: '20px',
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
    },
    tableData: {},
    tableRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingRight: '20px',
      color: '#684C67',
      borderTop: `1 solid ${palette.light.grey[200]}`,
    },
    tableCell: { padding: '10px 10px', fontSize: 7, fontWeight: 600 },
    tableCellText: {},
    tableTitle: {
      fontSize: 10,
    },
    tableTitleContainer: { paddingBottom: '10px' },
  });

  return styles;
};

export default setStyles;
