import {
  Box,
  Card,
  Typography,
  Button,
  Divider,
  Link,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  useTheme,
  CircularProgress,
} from '@mui/material';
import useAuth from '../../../../hooks/useAuth';
import { apiRoutes } from 'src/routes/api';
import { useEffect, useState } from 'react';
import { Users } from 'src/@types/localEntity';
import palette from 'src/theme/palette';
import CurrentCard from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import moment from 'moment';
import { ModalSubscriptionAlert } from './AccountModals/ModalSubscriptionAlert';
import { ModalCancelSubscription } from './AccountModals/ModalCancelSubscription';
import { ModalUpdatePayment } from './AccountModals/ModalUpdatePayment';
import { axiosInstance } from 'src/utils/axios';
import { PaymentForm } from 'src/sections/auth/register';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ModalChangePlans } from './AccountModals/ModalChangePlans';
import RegisterSubscription from './newSubscription';
import useResponsive from 'src/hooks/useResponsive';

// ----------------------------------------------------------------------

// type FormValuesProps = {
//   name: string;
//   email: string;
//   phone: string;
//   cpf: string;
//   license_type: string;
//   license_number: string;
// };

type CurrentUserType = {
  customer: Users;
  stripeCustomer: any;
  paymentMethods: any;
};

// const statusParser = (status?: string) => {
//   let style = {
//     color: '#3b3b3b',
//     backgroundColor: '#afafaf',
//   };

//   switch (status) {
//     case 'Pendente':
//       style = {
//         color: '#7A0C2E',
//         backgroundColor: '#FFA48D',
//       };
//       break;
//     case 'Pago':
//       style = {
//         color: '#08660D',
//         backgroundColor: '#AAF27F',
//       };
//       break;
//   }

//   return style;
// };

type StateProps = {
  payment_modal?: boolean;
  no_sub_modal?: boolean;
};

type AccountFinancialProps = {
  propState: StateProps | null;
};

export default function AccountFinancial({ propState }: AccountFinancialProps) {
  const mdUp = useResponsive('up', 'md');
  const smUp = useResponsive('up', 'sm');
  const theme = useTheme();
  const { user } = useAuth();
  const [currentUser, setCurrentUser] = useState<CurrentUserType>();
  const [subscription, setsubscription] = useState<any>({});
  const [stripePromise, setStripePromise] = useState<any>(null);
  const [openModalChangePlans, setOpenModalChangePlans] = useState(false);

  const [subscriptionModal, setSubscriptionModal] = useState(false);
  const openSubscriptionErrorModal = () => setSubscriptionModal(true);
  const closeSubscriptionErrorModal = () => setSubscriptionModal(false);

  const [cancelSubscriptionModal, setCancelSubscriptionModal] = useState(false);
  const openCancelSubscriptionModal = () => setCancelSubscriptionModal(true);
  const closeCancelSubscriptionModal = () => setCancelSubscriptionModal(false);

  const [openUpdatePayment, setOpenUpdatePayment] = useState(false);
  const handleUpdatePayment = () => {
    setOpenUpdatePayment(true);
  };

  const handleClose = () => {
    setOpenUpdatePayment(false);
  };

  const queryData = async () => {
    const customerData = await axiosInstance.get(
      apiRoutes.payment.getCustomerData(user?.external_id || '')
    );

    if (customerData) {
      setsubscription(customerData.data.customer.subscription[0]);
    } else {
      setsubscription(null);
      console.log(null);
    }

    setCurrentUser(customerData.data);
  };

  // const inConstructionMessage = () => {
  //   enqueueSnackbar('Em construção', { variant: 'warning' });
  // };

  function handleOpenChangePlans() {
    setOpenModalChangePlans(true);
  }
  function handleCloseChangePlans() {
    setOpenModalChangePlans(false);
  }

  useEffect(() => {
    if (propState) openSubscriptionErrorModal();

    queryData();
  }, []);

  useEffect(() => {
    setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ''));
  }, []);

  return (
    <Box>
      {subscription ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: `${!mdUp && 'column'}`,
              gap: '10px',
            }}
          >
            <Card sx={{ boxShadow: '1px 1px 5px 1px #00000022', width: '100%' }}>
              <Box sx={{ padding: '15px' }}>
                <Typography>Plano de assinatura</Typography>
              </Box>
              <Divider />
              <Box sx={{ padding: '25px' }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: `${!smUp && 'column'}`,
                    justifyContent: 'space-between',
                    marginBottom: '20px',
                  }}
                >
                  <Typography
                    variant="h4"
                    sx={{ color: palette.light.primary.main, fontWeight: 'bold' }}
                  >
                    {currentUser?.customer?.subscription[0]?.plan
                      ? `${
                          currentUser?.customer?.subscription[0]?.plan.name
                        }, R$ ${currentUser?.customer?.subscription[0]?.plan.value.toFixed(2).replace(".", ",")} / ${
                          currentUser?.customer?.subscription[0]?.plan.duration_months === 1
                            ? 'mês'
                            : `cada ${currentUser?.customer?.subscription[0]?.plan.duration_months} meses`
                        }`
                      : '-'}
                  </Typography>
                  <div
                    style={{
                      width: 'auto',
                      height: '24px',
                      padding: '5px',
                      borderRadius: '5px',
                      backgroundColor:
                        subscription?.plan_status === 'PAGO'
                          ? `${theme.palette.success.lighter}`
                          : subscription?.plan_status === 'PENDENTE'
                          ? `${theme.palette.warning.lighter}`
                          : subscription?.plan_status === 'CANCELADO'
                          ? `${theme.palette.error.lighter}`
                          : subscription?.plan_status === 'TRIAL'
                          ? `${theme.palette.info.lighter}`
                          : `${theme.palette.grey[300]}`,
                      fontSize: '14px',
                      color:
                        subscription?.plan_status === 'PAGO'
                          ? `${theme.palette.success.dark}`
                          : subscription?.plan_status === 'PENDENTE'
                          ? `${theme.palette.warning.dark}`
                          : subscription?.plan_status === 'CANCELADO'
                          ? `${theme.palette.error.dark}`
                          : subscription?.plan_status === 'TRIAL'
                          ? `${theme.palette.info.dark}`
                          : `${theme.palette.grey[800]}`,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {subscription?.plan_status}
                  </div>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                    gap: '10px',
                  }}
                >
                  <Box>
                    <Button fullWidth variant="contained" onClick={() => handleOpenChangePlans()}>
                      Alterar plano
                    </Button>
                  </Box>
                  {subscription.plan_status !== 'CANCELADO' &&
                    subscription.plan_status !== 'FINALIZADO' && (
                      <Box sx={{ display: 'flex', gap: '10px' }}>
                        <Button fullWidth variant="outlined" onClick={openCancelSubscriptionModal}>
                          Cancelar plano
                        </Button>
                      </Box>
                    )}
                </Box>
              </Box>
            </Card>
            <Card sx={{ boxShadow: '1px 1px 5px 1px #00000022', width: '100%' }}>
              <Box sx={{ padding: '15px' }}>
                {subscription?.plan_status !== 'PENDENTE' ? (
                  <Typography> Método de Pagamento</Typography>
                ) : (
                  <Typography>Realize o pagamento</Typography>
                )}
              </Box>
              <Divider />
              {subscription?.plan_status !== 'PENDENTE' ? (
                <Box sx={{ padding: '25px' }}>
                  <CurrentCard
                    name={currentUser?.customer?.name || ''}
                    number={`************${currentUser?.paymentMethods[0]?.card?.last4}`}
                    expiry={`${currentUser?.paymentMethods[0]?.card?.exp_month}/${currentUser?.paymentMethods[0]?.card?.exp_year}`}
                    cvc={111}
                    preview={true}
                  />
                  <Box sx={{ marginY: '15px' }}>
                    <Button fullWidth variant="outlined" onClick={() => handleUpdatePayment()}>
                      Alterar método de pagamento
                    </Button>
                  </Box>
                </Box>
              ) : (
                <Box>
                  {subscription.client_secret && stripePromise ? (
                    <Elements
                      stripe={stripePromise}
                      options={{ clientSecret: subscription.client_secret }}
                    >
                      <PaymentForm
                        activeStep={0}
                        userID={currentUser?.customer.id ? currentUser?.customer.id : ''}
                        isSetup={false}
                      />
                    </Elements>
                  ) : (
                    <div
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                    >
                      <CircularProgress size={'15px'} />
                    </div>
                  )}
                </Box>
              )}
            </Card>
          </Box>
          <Card sx={{ boxShadow: '1px 1px 5px 1px #00000022', width: '100%' }}>
            <Box sx={{ padding: '15px' }}>
              <Typography>Histórico de Pagamentos</Typography>
            </Box>
            <Divider />
            <TableContainer>
              <Table>
                {currentUser?.customer && currentUser?.customer?.payment?.length > 0 ? (
                  <TableBody>
                    {currentUser.customer.payment.map((payment, i) => (
                      <TableRow key={i}>
                        <TableCell style={{ textAlign: 'center' }}>
                          <Typography
                            sx={{
                              color: palette.light.primary.main,
                              fontWeight: 'bold',
                              minWidth: '200px',
                            }}
                          >
                            {payment.subscription.plan
                              ? `${
                                  payment.subscription.plan.name
                                }, R$ ${payment.subscription.plan.value.toFixed(2).replace(".", ",")} / ${
                                  payment.subscription.plan.duration_months === 1
                                    ? 'mês'
                                    : `cada ${currentUser?.customer?.subscription[0]?.plan.duration_months} meses`
                                }`
                              : '-'}
                          </Typography>
                        </TableCell>
                        <TableCell style={{ textAlign: 'center' }}>
                          <Typography>{moment(payment.payment_date).format('L')}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography sx={{ fontWeight: 'bold' }}>
                            {new Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            }).format(Number(payment.value))}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <div
                            style={{
                              width: 'auto',
                              height: '24px',
                              padding: '5px',
                              borderRadius: '5px',
                              backgroundColor: payment.completed
                                ? `${theme.palette.success.lighter}`
                                : `${theme.palette.warning.lighter}`,

                              fontSize: '14px',
                              color: payment.completed
                                ? `${theme.palette.success.dark}`
                                : `${theme.palette.warning.dark}`,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            {payment.completed ? 'PAGO' : 'PENDENTE'}
                          </div>
                        </TableCell>
                        <TableCell>
                          {payment.completed && payment.voucher ? (
                            <Link
                              sx={{
                                fontWeight: 'bold',
                                display: payment.completed ? 'block' : 'none',
                              }}
                              target="_blank"
                              href={payment.voucher}
                            >
                              Comprovante
                            </Link>
                          ) : (
                            ''
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  <></>
                )}
              </Table>
            </TableContainer>
          </Card>
        </Box>
      ) : (
        <RegisterSubscription user_id={user!.id} user_external_id={user!.external_id} />
      )}
      <ModalSubscriptionAlert
        open={subscriptionModal}
        handleCloseModal={closeSubscriptionErrorModal}
        type={propState}
      />
      {subscription && (
        <Box>
          <ModalCancelSubscription
            open={cancelSubscriptionModal}
            handleCloseModal={closeCancelSubscriptionModal}
            external_id={subscription!.external_id}
          />
          <ModalUpdatePayment
            open={openUpdatePayment}
            onClose={handleClose}
            userExternalId={user?.external_id}
            subscriptionId={subscription!.external_id}
          />
          <ModalChangePlans
            open={openModalChangePlans}
            handleClose={handleCloseChangePlans}
            subscriptionID={subscription!.id}
            user_id={currentUser?.customer.id ? currentUser?.customer.id : ''}
            reQuery={queryData}
          />
        </Box>
      )}
    </Box>
  );
}
