export enum Role {
  ADMIN = 'ADMIN',
  CUSTOMER = 'CUSTOMER',
}

export enum UserPlanStatus {
  PENDENTE = 'PENDENTE',
  ATRASADO = 'ATRASADO',
  PAGO = 'PAGO',
  CANCELADO = 'CANCELADO',
  FINALIZADO = 'FINALIZADO',
  TRIAL = 'TRIAL',
}
