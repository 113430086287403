import * as Yup from 'yup';
import { Box, Dialog, DialogContent, Stack, TextField, Typography, useTheme } from '@mui/material';
import { IoCloseOutline } from 'react-icons/io5';
import { LoadingButton } from '@mui/lab';
import { RHFTextField, FormProvider, RHFSelect } from 'src/components/hook-form';
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { yupResolver } from '@hookform/resolvers/yup';
import { axiosInstance } from 'src/utils/axios';
import { useSnackbar } from 'notistack';

type FormValuesProps = {
  cardNumber: string;
  cardName: string;
  cardVenc: string;
  cvv: string;
};
export interface FormDialogProps {
  open: boolean;
  onClose: () => void;
  subscriptionId: string;
  userExternalId: string;
}

export const ModalUpdatePayment = (props: FormDialogProps) => {
  const { onClose, open, userExternalId, subscriptionId } = props;
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const schema = Yup.object().shape({
    cardNumber: Yup.number().required(),
    cardName: Yup.string().required(),
    cardVenc: Yup.string().required(),
    cvv: Yup.string().required(),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(schema),
  });

  const {
    setError,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = methods;

  function formatDate(date: string) {
    const newDate = new Date(date);
    const dateFormatted = newDate.toISOString();
    const datePart = dateFormatted.split('T');
    return `${datePart[0]}`;
  }

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const response = await axiosInstance.post(`/payment/update_payment/${userExternalId}`, {
        subscriptionId,
        paymentForm: '',
        cardNumber: data.cardNumber,
        cardName: data.cardName,
        cardVenc: data.cardVenc,
        cvv: data.cvv,
      });
      onClose();
      enqueueSnackbar(response.data.message, { variant: 'success' });
      window.location.reload();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(error.message, { variant: 'error' });
      onClose();
    }
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogContent sx={{ maxWidth: '340px', padding: '0px' }}>
        <div
          style={{
            padding: '20px 20px 20px 20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography fontWeight={700}>Alterar forma de pagamento</Typography>
        </div>
        <Box sx={{ width: '100%', padding: '0 20px' }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2.5}>
              <Box>
                <Typography variant="subtitle2" color={theme.palette.primary.main}>
                  Forma de pagamento
                </Typography>
                <RHFSelect name="paymentForm" size="small">
                  <option style={{ padding: '10px' }} value="Credit-card" selected>
                    Cartão de credito
                  </option>
                </RHFSelect>
              </Box>

              <Box>
                <Typography variant="subtitle2" color={theme.palette.primary.main}>
                  Número do cartão
                </Typography>
                <Controller
                  name="cardNumber"
                  render={({ field, fieldState: { error } }) => (
                    <InputMask
                      mask="9999 9999 9999 9999"
                      value={field.value}
                      onChange={field.onChange}
                    >
                      <TextField
                        fullWidth
                        name="cardVenc"
                        type="text"
                        helperText={error?.message}
                        size="small"
                      />
                    </InputMask>
                  )}
                />
              </Box>
              <Box>
                <Typography variant="subtitle2" color={theme.palette.primary.main}>
                  Nome no cartão
                </Typography>
                <RHFTextField name="cardName" size="small" />
              </Box>
              <Box>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ width: '47%' }}>
                    <Typography variant="subtitle2" color={theme.palette.primary.main}>
                      Vencimento
                    </Typography>
                    <Controller
                      name="cardVenc"
                      render={({ field, fieldState: { error } }) => (
                        <InputMask mask="99/99" value={field.value} onChange={field.onChange}>
                          <TextField
                            name="cardVenc"
                            type="text"
                            helperText={error?.message}
                            size="small"
                          />
                        </InputMask>
                      )}
                    />
                  </Box>
                  <Box sx={{ width: '47%' }}>
                    <Typography variant="subtitle2" color={theme.palette.primary.main}>
                      CVV
                    </Typography>
                    <Controller
                      name="cvv"
                      render={({ field, fieldState: { error } }) => (
                        <InputMask mask="999" value={field.value} onChange={field.onChange}>
                          <TextField
                            name="cvv"
                            type="text"
                            helperText={error?.message}
                            size="small"
                          />
                        </InputMask>
                      )}
                    />
                  </Box>
                </div>
              </Box>
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ my: 2 }}
            >
              Salvar
            </LoadingButton>
          </FormProvider>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
