// hooks
import { axiosInstance } from 'src/utils/axios';
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar, { Props as AvatarProps } from './Avatar';
import { apiRoutes } from 'src/routes/api';
import { useEffect, useState } from 'react';

import Image from '../components/Image';
import { HOST_API } from 'src/config';

// ----------------------------------------------------------------------

interface NewAvatarProps extends AvatarProps {
  url?: string;
}

export default function MyAvatar({ url, ...other }: NewAvatarProps) {
  const { user } = useAuth();

  const [hasImage, setHasImage] = useState(false);

  useEffect(() => {
    if (!url) {
      queryImage();
    }
  }, []);

  const queryImage = async () => {
    await axiosInstance
      .get(apiRoutes.users.logo(user?.id || ''))
      .then((response) => {
        setHasImage(true);
      })
      .catch((error) => {
        setHasImage(false);
      });
  };

  return (
    <Avatar src={user?.id} alt={user?.name} color={createAvatar(user?.name || '').color} {...other}>
      {hasImage || url ? (
        <Image
          src={url || `${HOST_API}${apiRoutes.users.logo(user?.id || '')}`}
          alt="Perfil"
          style={{
            objectFit: 'cover',
            minWidth: '40px',
            minHeight: '45px',
          }}
        />
      ) : (
        createAvatar(user?.name || '').name
      )}
    </Avatar>
  );
}
