export default function getImageResizedDimentions(
  maxWidth: number,
  maxHeight: number,
  imageWidth: number,
  imageHeight: number
) {
  const originalAspectRatio = imageWidth / imageHeight;

  let novaWidth, novaHeight;

  if (imageWidth <= maxWidth && imageHeight <= maxHeight) {
    novaWidth = imageWidth;
    novaHeight = imageHeight;
  } else {
    if (imageWidth / maxWidth > imageHeight / maxHeight) {
      novaWidth = maxWidth;
      novaHeight = maxWidth / originalAspectRatio;
    } else {
      novaHeight = maxHeight;
      novaWidth = maxHeight * originalAspectRatio;
    }
  }

  return { width: novaWidth, height: novaHeight };
}
