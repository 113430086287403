import * as Yup from 'yup';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  useTheme,
  Stack,
  RadioGroup,
  Divider,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import Iconify from 'src/components/Iconify';
import { FormProvider, RHFRadioGroup, RHFTextField } from 'src/components/hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  JSXElementConstructor,
  ReactElement,
  ReactFragment,
  ReactPortal,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { useSnackbar } from 'notistack';
import { axiosInstance } from 'src/utils/axios';

type FormValuesProps = {
  plans: string;
};

interface ModalConfirmationsProps {
  open: boolean;
  handleClose: () => void;
  subscriptionID: string | null;
  user_id: string;
  reQuery: () => void;
}

export function ModalChangePlans({
  open,
  handleClose,
  subscriptionID,
  user_id,
  reQuery,
}: ModalConfirmationsProps) {
  const theme = useTheme();
  const [selectedValue, setSelectedValue] = useState('');
  const [optionsPlans, setOptionsPlans] = useState([]);
  const [allPlans, setAllPlans] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const getPlans = async () => {
    const { data } = await axiosInstance.get('/plans/findAllPlans');

    const opt = data
      .filter((i: any) => i.active && i.public)
      .map((i: any) => ({
        label: `${i.name} - ${new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }).format(Number(i.value))} / mes`,
        value: i.id,
      }));

    setOptionsPlans(opt);
  };

  // let aaa:[] = [];

  // if (allPlans && selectedValue) {
  //   aaa = allPlans.filter((i: any) => i.id === selectedValue);
  // }

  const RegisterSchema = Yup.object().shape({
    plans: Yup.string().required('Selecione um plano'),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(RegisterSchema),
  });

  const handleRadioChange = (event: any) => {
    setSelectedValue(event.target.value);
  };

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    if (subscriptionID) {
      try {
        await axiosInstance.post('/plans/updatePlans', {
          subscription: subscriptionID,
          newPlan: data.plans,
        });
        enqueueSnackbar('Plano Alterado com sucesso!', { variant: 'success' });
        reQuery(); /*  */
      } catch (error) {
        console.error(error);
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    } else {
      try {
        await axiosInstance.post('/subscription/createByAdmin', {
          user_id: user_id,
          newPlan: data.plans,
        });
        enqueueSnackbar('Nova assinatura criada com sucesso!', { variant: 'success' });
        reQuery();
      } catch (error) {
        console.error(error);
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
    handleClose();
  };

  useEffect(() => {
    getPlans();
  }, []);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 600, fontSize: '18px' }}>
          Alterar plano de assinatura
        </Typography>
        <div
          style={{
            borderRadius: '3px',
            width: '20px',
            height: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: theme.palette.grey[300],
            boxShadow: '0px 1px 2px rgba(145, 158, 171, 0.24)',
            cursor: 'pointer',
          }}
          onClick={() => handleClose()}
        >
          <Iconify icon="material-symbols:close" fontSize="10px" color={theme.palette.grey[600]} />
        </div>
      </DialogTitle>

      <DialogContent sx={{ marginTop: '20px' }}>
        <DialogContentText id="alert-dialog-description">
          <Typography
            variant="subtitle2"
            style={{
              fontWeight: '600',
              fontSize: '14px',
              color: 'black',
            }}
          >
            Selecione um dos planos de assinatura
          </Typography>
        </DialogContentText>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <RHFRadioGroup name="plans" value={selectedValue} options={optionsPlans} />
          </Stack>
          {/* <Divider sx={{ margin: '25px 0' }} /> */}
          {/* <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '10px 0',
            }}
          >
            <Typography variant="body2">Valor original</Typography>
            <Typography variant="body2">
              {new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(Number(10))}
              / mes
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '10px 0',
            }}
          >
            <Typography variant="body2">Descontos</Typography>
            <Typography variant="body2">
              -
              {new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(Number(0))}
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: '10px 0',
            }}
          >
            <Typography variant="subtitle2">Valor total atual</Typography>
            <Typography variant="subtitle2">
              {new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(Number(10))}
              / mes
            </Typography>
          </div> */}
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              salvar
            </LoadingButton>
          </div>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
