import { FormEvent, useState } from 'react';
import { Box, Card, Typography, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useElements, useStripe, PaymentElement } from '@stripe/react-stripe-js';
import { useSnackbar } from 'notistack';
// ----------------------------------------------------------------------

// type CardValuesProps = {
//   holder_name: string;
//   number: string;
//   expiration: string;
//   cvc: string;
//   flag: string;
// };

type PaymentFormProps = {
  activeStep: number;
  handlePrevious?: () => void;
  userID: string;
  isSetup: boolean;
};

export default function PaymentForm({ handlePrevious, userID, isSetup = false }: PaymentFormProps) {
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [cardVerification, setCardVerification] = useState(false);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    const paymentData = isSetup
      ? await stripe.confirmSetup({
          elements,
          confirmParams: {
            return_url: handlePrevious
              ? `${window.location.origin}/auth/payment-confirmed/${userID}`
              : `${window.location.origin}/dashboard/account`,
          },
        })
      : await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: handlePrevious
              ? `${window.location.origin}/auth/payment-confirmed/${userID}`
              : `${window.location.origin}/dashboard/account`,
          },
        });

    const { error } = paymentData;

    if (!error) {
      enqueueSnackbar('Pagamento confirmado com sucesso!');

      // await axiosInstance.post(apiRoutes.subscriptions.confirmSubscription(userID))
    }

    if (error.type === 'card_error' || error.type === 'validation_error') {
      enqueueSnackbar(error.message, { variant: 'error' });
    } else {
      enqueueSnackbar('Um erro inesperado aconteceu', { variant: 'error' });
    }

    setIsProcessing(false);
  };

  return (
    <>
      <Card sx={{ flexGrow: 10, boxShadow: '1px 1px 5px 1px #00000022' }}>
        {handlePrevious && (
          <Box sx={{ padding: '15px' }}>
            <Typography>Método de Pagamento</Typography>
          </Box>
        )}

        <Box sx={{ padding: '15px' }}>
          <PaymentElement id="payment" onChange={(e) => setCardVerification(e.complete)} />
        </Box>
      </Card>
      <Box>
        {handlePrevious && (
          <Button fullWidth size="large" variant="outlined" sx={{ mt: 3 }} onClick={handlePrevious}>
            Voltar
          </Button>
        )}

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isProcessing}
          disabled={!cardVerification}
          onClick={handleSubmit}
          sx={{ mt: 3 }}
        >
          Finalizar
        </LoadingButton>
      </Box>
    </>
  );
}
