import { useEffect, useState } from 'react';
import {
  Stack,
  InputAdornment,
  TextField,
  Box,
  Button,
  Card,
  Typography,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
  useTheme,
} from '@mui/material';
import { axiosInstance } from 'src/utils/axios';
import { apiRoutes } from 'src/routes/api';
import { Plans } from 'src/@types/localEntity';
import { useSnackbar } from 'notistack';
import palette from 'src/theme/palette';
import { LoadingButton } from '@mui/lab';
import Iconify from 'src/components/Iconify';

// ----------------------------------------------------------------------

type PlanFormProps = {
  activeStep: number;
  handleNext: () => void;
  handlePrevious: () => void;
  handleSetClientSecret: (clientSecret: string, isSetup: boolean) => void;
  customerID: string;
  userID: string;
};

export default function RegisterForm({
  activeStep,
  handleNext,
  customerID,
  userID,
  handleSetClientSecret,
}: PlanFormProps) {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [availablePlans, setAvailablePlans] = useState<Plans[]>([]);
  const [planSelected, setPlanSelected] = useState<Plans>();

  const [ticketValue, setTicketValue] = useState('');
  const [ticketApplySuccess, setTicketApplySuccess] = useState(false);
  const [ticketText, setTicketText] = useState('');
  const [ticketapplied, setTicketapplied] = useState<string | null>(null);
  const [discount, setDiscount] = useState('0.00');

  const handleTicketChange = (event: any) => {
    setTicketValue(event.target.value);
  };

  const applyTicket = async () => {
    if (ticketApplySuccess) {
      setTicketApplySuccess(false);
      setTicketText('');
    } else {
      await axiosInstance
        .get(`payment/ticket/${ticketValue}`)
        .then((data) => {
          if (data.data.valid) {
            setTicketApplySuccess(true);

            setTicketapplied(data.data.id);

            const discountValue = Number(
              (data.data.percent_off * planSelected!.value) / 100
            ).toFixed(2);

            setDiscount(discountValue);

            if (data.data.duration === 'forever') {
              setTicketText(`${data.data.percent_off}% de desconto`);
            } else if (data.data.duration === 'once') {
              setTicketText(`${data.data.percent_off}% de desconto por 1 mês.`);
            } else {
              setTicketText(
                `${data.data.percent_off}% de desconto por ${data.data.duration_in_months} meses.`
              );
            }
          } else {
            setTicketText('Código inválido.');
          }
        })
        .catch((error) => {
          console.log(error);
          setTicketText('Código inválido.');
        });
    }
  };

  const queryData = async () => {
    const { data } = await axiosInstance.get('/plans/listPlans');
    setAvailablePlans(data);
  };

  useEffect(() => {
    queryData();
  }, []);

  const handleSubmit = async () => {
    setIsLoading(true);

    await axiosInstance
      .post(apiRoutes.payment.createSubscription(), {
        customer_id: customerID,
        product_id: planSelected?.external_id,
        user_id: userID,
        plan_id: planSelected?.id,
        coupon: ticketapplied,
      })
      .then((data) => {
        const { clientSecret, isSetup } = data.data;
        handleSetClientSecret(clientSecret, isSetup);
        handleNext();
      })
      .catch((e) => enqueueSnackbar(e.message, { variant: 'error' }));

    setIsLoading(false);
  };

  return (
    <>
      <Stack spacing={2} sx={{ flexGrow: 10 }}>
        <Box sx={{ display: 'flex', gap: '15px', width: '100%' }}>
          <Card sx={{ flexGrow: 10, boxShadow: '1px 1px 5px 1px #00000022' }}>
            <Box sx={{ padding: '15px' }}>
              <Typography>Plano de assinatura</Typography>
            </Box>
            <Divider />
            <Box sx={{ padding: '25px' }}>
              <RadioGroup
                onChange={(e) => {
                  const selectedPlan = availablePlans.filter((plan) => plan.id === e.target.value);
                  setPlanSelected(selectedPlan[0]);
                }}
              >
                {availablePlans.map((plan, index) => (
                  <FormControlLabel
                    key={plan.id}
                    value={plan.id}
                    control={<Radio />}
                    label={`${plan.name}, R$ ${plan.value.toFixed(2).replace(".", ",")} / ${
                      plan.duration_months === 1 ? 'mês' : `cada ${plan.duration_months} meses`
                    }`}
                  />
                ))}
              </RadioGroup>
            </Box>
            <Divider />
            <Box sx={{ padding: '15px' }}>
              <TextField
                fullWidth
                variant={ticketApplySuccess ? 'filled' : 'outlined'}
                label="Voucher de descontos"
                value={ticketValue}
                onChange={(e) => handleTicketChange(e)}
                disabled={ticketApplySuccess || !planSelected}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {planSelected && (
                        <Button onClick={() => applyTicket()}>
                          {ticketApplySuccess ? (
                            <Iconify
                              icon={'ri:close-fill'}
                              color="red"
                              sx={{ width: 20, height: 20 }}
                            />
                          ) : (
                            'aplicar'
                          )}
                        </Button>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
              <p style={{ fontSize: '12px', color: `${theme.palette.primary.main}` }}>
                {ticketText}
              </p>
            </Box>
            <Divider />
            <Box sx={{ padding: '25px' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography>Valor original</Typography>
                <Typography>
                  {planSelected ? `R$ ${planSelected.value.toFixed(2).replace(".", ",")}` : 'R$ 0,00'}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography>Descontos</Typography>
                <Typography>R$ {discount.replace(".", ",")}</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography sx={{ fontWeight: 'bold' }}>Valor total atual</Typography>
                <Typography sx={{ fontWeight: 'bold', color: palette.light.primary.main }}>
                  {planSelected
                    ? `R$ ${(Number(planSelected.value) - Number(discount)).toFixed(2).replace(".", ",")}`
                    : 'R$ 0,00'}
                </Typography>
              </Box>
            </Box>
          </Card>
        </Box>
      </Stack>
      {/* <Button
        fullWidth
        size="large"
        variant="outlined"
        sx={{ mt: 3 }}
        onClick={handlePrevious}
      >
        Voltar
      </Button> */}
      <LoadingButton
        fullWidth
        size="large"
        variant="contained"
        sx={{ mt: 3 }}
        disabled={!Boolean(planSelected)}
        loading={isLoading}
        onClick={handleSubmit}
      >
        Continuar
      </LoadingButton>
    </>
  );
}
