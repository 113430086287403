import { PDFViewer, Document, Page, View, Image, Text, PDFDownloadLink } from '@react-pdf/renderer';
import React, { useEffect, useState } from 'react';
import setStyles from './styles';
import { apiRoutes } from 'src/routes/api';
import {
  ParallelCalc,
  parallelCalcOutput,
  Projects,
  Spdas,
  Structures,
  Users,
  imageDimentions,
} from 'src/@types/localEntity';
import { axiosInstance } from 'src/utils/axios';
import { Box, Button, CircularProgress, useTheme, Typography } from '@mui/material';
import { ParallelCalcChart } from 'src/components/chart/parallelCalcChart';
import useResponsive from 'src/hooks/useResponsive';
import { fReport } from 'src/utils/formatNumber';
import historyGoBack from 'src/utils/historyGoBack';
import getImageResizedDimentions from 'src/utils/getImageResizedDimentions';
import { HOST_API } from 'src/config';

export default function ParallelCalcReport() {
  const [styles, setReportStyles] = useState(setStyles());
  const Logo = '/assets/logo/brc_lightning_blue.png';
  const LogoModule = '/assets/icons/modules/esferas-rolantes.png';

  interface LocationState {
    calcs_id: string[];
  }

  const isMobile = useResponsive('down', 'sm');
  const theme = useTheme();

  const noView = true;

  const calcs_id = JSON.parse(localStorage.getItem('reportIds') || '');

  const [calcs, setCalcs] = useState<ParallelCalc[]>([]);

  const [loading, setLoading] = useState<boolean>(true);

  const [imageDimentions, setImageDimentions] = useState<imageDimentions>();

  const queryData = async () => {
    const calcsData = await axiosInstance.put(apiRoutes.parallelCalcs.reportList(), {
      calcs_id: calcs_id,
    });
    setCalcs(calcsData.data);

    if (calcsData.data[0].project.user.s3_image_url) {
      const imageData = await axiosInstance.get(
        apiRoutes.users.logoSize(calcsData.data[0].project.user.id)
      );

      setImageDimentions(
        getImageResizedDimentions(140, 50, imageData.data.width, imageData.data.height)
      );
    }

    setLoading(false);
  };

  useEffect(() => {
    queryData();
  }, []);

  const getDataUri = async (id: string) =>
    ApexCharts.exec(id, 'dataURI', { width: '700' }).then(({ imgURI }: any) => imgURI);

  interface headerProps {
    project: Projects;
  }

  const Header = ({ project }: headerProps) => (
    <View style={styles.header}>
      <View style={styles.logoContainer}>
        <Image
          style={{
            ...styles.logo,
            ...(project.user?.s3_image_url
              ? { width: imageDimentions?.width, height: imageDimentions?.height }
              : {}),
          }}
          source={
            project?.user?.s3_image_url
              ? `${HOST_API}${apiRoutes.users.logo(project?.user?.id || '')}`
              : Logo
          }
        />
      </View>
      <View style={styles.moduleContainer}>
        <View style={{ ...styles.logoModuleContainer, ...styles.logoModuleContainerLight }}>
          <Image style={styles.logoModule} source={LogoModule} />
        </View>
        <Text style={styles.moduleText}>Método da Esfera Rolante</Text>
      </View>
    </View>
  );

  const Footer = () => (
    <View style={styles.footer}>
      <View style={styles.logoContainerFooter}>
        <Image style={styles.logoFooter} source={Logo} />
      </View>
      <Text style={styles.textFooter}>
        BRC Lghtning © {new Date().getFullYear().toString()} | Todos os direitos reservados.
      </Text>
    </View>
  );

  interface page1Props {
    project: Projects;
    user: Users;
    spdas: Spdas[];
    structure: Structures;
    calc: ParallelCalc;
    calcOutput?: parallelCalcOutput;
  }
  const Page1 = ({ project, user, spdas, structure, calc, calcOutput }: page1Props) => (
    <Page size="A4" style={[styles.page, { padding: 0 }]}>
      <Header project={project} />
      <View style={styles.container}>
        <View style={styles.titleContainer}>
          <Text style={styles.title}>
            Cálculo de SPDA Paralelo - Rev{' '}
            {`${
              calc.version > 0 && calc.version <= 27 ? String.fromCharCode(calc.version + 64) : 0
            }`}
          </Text>
        </View>
        <View style={styles.article}>
          <Text style={styles.articleTitle}>Definições do projeto e do responsável</Text>

          <View style={styles.section}>
            <View style={styles.ParameterContainer}>
              <Text style={styles.ParameterName}>Norma considerada</Text>
              <Text style={styles.ParameterData}>{project.norm?.name}</Text>
            </View>

            <View style={styles.ParameterContainer}>
              <Text style={styles.ParameterName}>Responsável Técnico</Text>
              <Text style={styles.ParameterData}>{project?.user?.name || ''}</Text>
            </View>

            <View style={styles.ParameterContainer}>
              <Text style={styles.ParameterName}>Nome do Projeto/REV</Text>
              <Text style={styles.ParameterData}>{project.name}</Text>
            </View>
          </View>
          <View style={styles.section}>
            {spdas.map((spda) => (
              <View style={styles.ParameterContainer} key={spda.id}>
                <Text style={styles.ParameterName}>{`SPDA - ${spda.number}`}</Text>
                <Text style={styles.ParameterData}>{`${spda.type.name} ${spda.description}`}</Text>
              </View>
            ))}

            <View style={styles.ParameterContainer}>
              <Text style={styles.ParameterName}>Estrutura a ser protegida</Text>
              <Text style={styles.ParameterData}>{`${structure.name}`}</Text>
            </View>
          </View>

          <View style={styles.sectionSide}>
            <View style={styles.ParameterContainerSide}>
              <Text style={styles.ParameterName}>Há uma atmosfera explosiva?</Text>
              <Text style={styles.ParameterData}>{calc.explosive_atmosphere ? 'SIM' : 'NÃO'}</Text>
            </View>

            <View style={styles.ParameterContainerSide}>
              <Text style={styles.ParameterName}>
                A estrutura está entre dois elementos captores?
              </Text>
              <Text style={styles.ParameterData}>{calc.dpi ? 'SIM' : 'NÃO'}</Text>
            </View>
          </View>
        </View>

        <View style={styles.article}>
          <Text style={styles.articleTitle}>
            Dados da parametização para o dimensionamento da altura (h) do SPDA
          </Text>

          <View style={styles.sectionSide}>
            <View style={styles.ParameterContainerSide}>
              <Text style={styles.ParameterName}>Classe do SPDA</Text>
              <Text style={styles.ParameterData}>{`${calc.project_class?.name}`}</Text>
            </View>

            {calc.explosive_atmosphere && (
              <View style={styles.ParameterContainerSide}>
                <Text style={styles.ParameterName}>Raio 'R' da área classificada</Text>
                <Text style={styles.ParameterData}>{`${fReport(calc.ea_radius)} m`}</Text>
              </View>
            )}

            <View style={styles.ParameterContainerSide}>
              <Text style={styles.ParameterName}>Altura de projeto 'hp' do SPDA</Text>
              <Text style={styles.ParameterData}>{`${fReport(calc.spda_height)} m`}</Text>
            </View>

            <View style={styles.ParameterContainerSide}>
              <Text style={styles.ParameterName}>Altura h1 da estrutura</Text>
              <Text style={styles.ParameterData}>{`${fReport(structure.height)} m`}</Text>
            </View>

            <View style={styles.ParameterContainerSide}>
              <Text style={styles.ParameterName}>Distância horizontal d1 da estrutura</Text>
              <Text style={styles.ParameterData}>{`${fReport(
                calc.structure_distance || 0
              )} m`}</Text>
            </View>

            <View style={styles.ParameterContainerSide}>
              <Text style={styles.ParameterName}>Largura L1 da estrutura</Text>
              <Text style={styles.ParameterData}>{`${fReport(structure.width)} m`}</Text>
            </View>

            {calc.dpi && (
              <View style={styles.ParameterContainer}>
                <Text style={styles.ParameterName}>
                  Distância horizontal protegida, pelo SPDA, na instalação ‘dpi’
                </Text>
                <Text style={styles.ParameterData}>{`${fReport(calc.dpi_distance_left)} m`}</Text>
                <Text style={styles.ParameterData}>{`${fReport(calc.dpi_distance_right)} m`}</Text>
              </View>
            )}

            <View style={styles.ParameterContainer}>
              <Text style={styles.ParameterName}>
                Margem de cobertura 'mc' da envoltória do volume de proteção do SPDA
              </Text>
              <Text style={styles.ParameterData}>{`${fReport(calc.margin)} m`}</Text>
            </View>
          </View>
        </View>

        <View style={styles.articleResult}>
          <Text style={styles.articleTitle}>Resultados do dimensionamento de SPDA Paralelo</Text>
          <View style={styles.ParameterContainerResult}>
            <Text style={styles.ParameterNameResult}>
              {`Altura 'hc' calculada para o SPDA de proteção da estrutura:`}
            </Text>
            <Text style={styles.ParameterDataResult}>{`${fReport(
              calcOutput?.output_spda_height || 0
            )} m`}</Text>
          </View>
          <View style={styles.ParameterContainerResult}>
            <Text style={styles.ParameterNameResult}>
              {`Margem de cobertura de projeto 'mcp' proporcionada pelo SPDA:`}
            </Text>
            <Text style={styles.ParameterDataResult}>{`${fReport(
              calcOutput?.output_margin || 0
            )} m`}</Text>
          </View>
          {/* <View style={styles.ParameterContainerResult}>
            <Text style={styles.ParameterNameResult}>
              {`Altura limite para a estrutura mantida a distância - h1 (Lim):`}
            </Text>
            <Text style={styles.ParameterDataResult}>{`${fReport(
              calcOutput.structure_limit_height
            )} m`}</Text>
          </View> */}
          {/* <View style={styles.ParameterContainerResult}>
            <Text style={styles.ParameterNameResult}>
              {`Distância horizontal limite para a estrutura mantida a altura - d1 (Lim):`}
            </Text>
            <Text style={styles.ParameterDataResult}>{`${fReport(
              calcOutput.structure_limit_horizontal_distance
            )} m`}</Text>
          </View> */}
          {/* 
          {calc.explosive_atmosphere && (
            <View style={styles.ParameterContainerResult}>
              <Text style={styles.ParameterNameResult}>
                {`Altura Limite do ponto crítico da área explosiva da estrutura - h1L_Ae:`}
              </Text>
              <Text style={styles.ParameterDataResult}>{`${fReport(
                calcOutput.ae_limit_height
              )} m`}</Text>
            </View>
          )} */}
          {/* {calc.explosive_atmosphere && (
            <View style={styles.ParameterContainerResult}>
              <Text style={styles.ParameterNameResult}>
                {`Distância horizontal Limite do ponto crítico da área explosiva\n da estrutura - d1L_Ae:`}
              </Text>
              <Text style={styles.ParameterDataResult}>{`${fReport(
                calcOutput.ae_limit_horizontal_distance
              )} m`}</Text>
            </View>
          )} */}
        </View>
      </View>

      <Footer />
    </Page>
  );

  interface page2Props {
    calc: ParallelCalc;
    project: Projects;
    calcOutput?: parallelCalcOutput;
  }
  const Page2 = ({ calc, project, calcOutput }: page2Props) => (
    <Page size="A4" style={[styles.page, { padding: 0 }]}>
      <Header project={project} />
      <View style={styles.container}>
        <View style={styles.article}>
          <Text style={styles.articleTitle}>GRÁFICO DO RESULTADO</Text>
          <Text style={styles.articleText}>
            {`Cálculo de SPDA Paralelo para a ${calc.project_class?.name} ${
              calc.explosive_atmosphere
                ? 'e limite da estrutura com área classificada incorporada'
                : ''
            }`}
          </Text>
          <View style={styles.chartContainer}>
            <Image
              source={async () => {
                let link = await getDataUri(calc.id);
                return Promise.resolve(link);
              }}
            />
          </View>

          <View
            style={
              calcOutput?.diagnostic_is_valid ? styles.statusContainer : styles.statusContainerError
            }
          >
            <View style={styles.statusLogoContainer}>
              <Image style={styles.statusLogo} source={'icon-check'} />
            </View>
            <View style={styles.statusTextContainer}>
              <Text
                style={
                  calcOutput?.diagnostic_is_valid ? styles.statusTitle : styles.statusTitleError
                }
              >
                {calcOutput?.diagnostic_is_valid
                  ? 'Instalação adequada.'
                  : 'Instalação inadequada.'}
              </Text>
              <Text
                style={
                  calcOutput?.diagnostic_is_valid
                    ? styles.statusDescription
                    : styles.statusDescriptionError
                }
              >
                {calcOutput?.diagnostic_is_valid
                  ? 'O condutor de descida não transpassará a área classificada e atende a margem de cobertura de projeto.'
                  : calcOutput?.diagnostic_message}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.articleBottom}>
          <View style={styles.section}>
            <Text style={styles.articleTitle}>Parecer Técnico</Text>
            <Text style={styles.technicalFeedback}>{`${calc.technical_report}`}</Text>
          </View>
        </View>

        <SignatureArea
          responsible={project?.user?.name || ''}
          licenseNumber={project?.user?.license_number || ''}
          licenseType={project?.user?.license_type || ''}
        />
      </View>
      <Footer />
    </Page>
  );

  interface signatureAreaProps {
    responsible: string;
    licenseNumber: string;
    licenseType: string;
  }
  const SignatureArea = ({ responsible, licenseNumber, licenseType }: signatureAreaProps) => (
    <View style={styles.sinatureContainer}>
      <View style={styles.signatureArea}>
        <View style={styles.signatureLine} />
        <Text style={styles.signatureText}>
          {`Responsável Técnico: `}
          <Text style={styles.signatureTextData}>{`   ${responsible}`}</Text>
        </Text>
        <Text style={styles.signatureText}>
          {`${licenseType}: `}
          <Text style={styles.signatureTextData}>{`   ${licenseNumber}`}</Text>
        </Text>
      </View>
    </View>
  );

  const PrintReport = () => (
    <>
      {calcs.length > 0 &&
        calcs.map((calc) => {
          if (
            !calc.structure ||
            !calc.project ||
            !calc.project?.user ||
            !calc.spdas ||
            !calc.output
          ) {
            return;
          }
          return (
            <React.Fragment key={calc.id}>
              <Page1
                project={calc.project}
                user={calc.project?.user}
                spdas={calc.spdas}
                structure={calc.structure}
                calc={calc}
                calcOutput={calc.output}
              />
              <Page2 project={calc.project} calc={calc} calcOutput={calc.output} />
            </React.Fragment>
          );
        })}
    </>
  );

  const MyDocument = () => (
    <Document
      title="Relatório de Cálculo de SPDA Paralelo"
      author="BRC Lightning"
      keywords="Relatório"
    >
      <PrintReport />
    </Document>
  );

  return (
    <>
      {loading ? (
        <div
          style={{
            width: '100%',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </div>
      ) : isMobile || noView ? (
        <>
          {calcs.length &&
            calcs.map((calc) => (
              <Box
                sx={{ minWidth: '700px', position: 'absolute', visibility: 'hidden' }}
                key={calc.id}
              >
                <ParallelCalcChart
                  id={calc?.id}
                  hcAux={calc.output?.hc_aux || 0}
                  gap={calc?.gap || 0}
                  R={calc?.project_class_radius || 0}
                  r={calc?.ea_radius || 0}
                  dAe={calc?.ea_distance || 0}
                  h={calc?.spda_height || 0}
                  h1={calc?.structure?.height || 0}
                  d1={calc?.structure_distance || 0}
                  l1={calc?.structure?.width || 0}
                  dp={calc?.output?.dp || 0}
                  dpc={calc?.output?.dpc || 0}
                  hcpj={calc?.output?.hcpj || 0}
                />
              </Box>
            ))}

          <div
            style={{
              width: '100%',
              height: '100vh',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '20px',
            }}
          >
            <Typography color={theme.palette.primary.main} fontSize="18px" fontWeight="bold">
              Relatório de Cálculo de SPDA Paralelo!
            </Typography>

            <PDFDownloadLink
              document={<MyDocument />}
              fileName="Relatório_Cálculo_de_SPDA_Paralelo"
              style={{ textDecoration: 'none' }}
            >
              {({ blob, url, loading, error }) =>
                loading ? (
                  <CircularProgress />
                ) : (
                  <Button variant="contained" sx={{ textTransform: 'none' }}>
                    Clique aqui para realizar o download!
                  </Button>
                )
              }
            </PDFDownloadLink>

            <Button variant="contained" color="error" onClick={historyGoBack}>
              Voltar
            </Button>
          </div>
        </>
      ) : (
        <>
          {calcs.length &&
            calcs.map((calc) => (
              <Box sx={{ minWidth: '700px', position: 'absolute' }} key={calc.id}>
                <ParallelCalcChart
                  id={calc?.id}
                  hcAux={calc.output?.hc_aux || 0}
                  gap={calc?.gap || 0}
                  R={calc?.project_class_radius || 0}
                  r={calc?.ea_radius || 0}
                  dAe={calc?.ea_distance || 0}
                  h={calc?.spda_height || 0}
                  h1={calc?.structure?.height || 0}
                  d1={calc?.structure_distance || 0}
                  l1={calc?.structure?.width || 0}
                  dp={calc?.output?.dp || 0}
                  dpc={calc?.output?.dpc || 0}
                  hcpj={calc?.output?.hcpj || 0}
                />
              </Box>
            ))}
          <PDFViewer
            style={{ width: '100%', height: '100%', position: 'absolute' /*top: 0, left: 0*/ }}
          >
            <MyDocument />
          </PDFViewer>
        </>
      )}
    </>
  );
}
