import * as Yup from 'yup';
import { useState } from 'react';
import {
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import InputMask from 'react-input-mask';
import { LoadingButton } from '@mui/lab';
import Iconify from '../../../components/Iconify';
import { FormikProvider, Form, useFormik } from 'formik';
import { axiosInstance } from 'src/utils/axios';
import { apiRoutes } from 'src/routes/api';
import { Users } from 'src/@types/localEntity';
import { Role } from 'src/@types/localEnum';
import { useSnackbar } from 'notistack';

// ----------------------------------------------------------------------

export const regexCamp = (value: any) => /^(?=.*[_])./gm.test(value);
export const regexCampCnpj = (value: string) => {
  const regex = /^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/;
  return regex.test(value);
};


type RegisterFormProps = {
  activeStep: number;
  handleNext: () => void;
  handleSetUserCreatedID: (value: string) => void;
  handleSetStripeCustomerID: (value: string) => void;
};

type FormValuesProps = {
  name: string;
  cpf: string;
  email: string;
  phone: string;
  license_type: string;
  license_number: string;
  password: string;
  confirmationPassword: string;
  afterSubmit?: string;
  cnpj: string;
  nameCompany: string
};

export default function RegisterForm({
  activeStep,
  handleNext,
  handleSetStripeCustomerID,
  handleSetUserCreatedID,
}: RegisterFormProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);

  const theme = useTheme();
  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required('Digite o nome completo').nullable(),
    nameCompany: Yup.string(),
    cpf: Yup.string()
      .required('Digite um CPF')
      .test(
        'Digite o CPF corretamente!',
        'Digite o CPF corretamente!',
        (value: any) => !regexCamp(value)
      )
      .nullable(),
    cnpj: Yup.string()
      .test(
        'Digite o CNPJ corretamente!',
        'Digite o CNPJ corretamente!',
        (value: any) => !regexCampCnpj(value)
      )
      .nullable(),
    email: Yup.string()
      .email('O e-mail deve ser um endereço de e-mail válido')
      .required('Email é Obrigatório')
      .nullable(),
    phone: Yup.string()
      .required('Digite o número de contato')
      .test(
        'Digite o número de celular corretamente!',
        'Digite o número de celular corretamente!',
        (value: any) => !regexCamp(value)
      )
      .nullable(),
    license_type: Yup.string().required('Digite o nome completo').nullable(),
    license_number: Yup.string().required('Digite o nome completo').nullable(),
    password: Yup.string()
      .matches(/.*\d/, 'A senha deve conter pelo menos um dígito (0-9).')
      .matches(/.*\W+/, 'A senha deve conter pelo menos um caractere não alfanumérico.')
      .test(
        'no-dot-or-newline',
        'A senha não deve conter um ponto ou quebra de linha.',
        (value) => {
          return value !== null && value !== undefined && !/[.\n]/.test(value);
        }
      )
      .matches(/.*[A-Z]/, 'A senha deve conter pelo menos uma letra maiúscula.')
      .matches(/.*[a-z]/, 'A senha deve conter pelo menos uma letra minúscula.')
      .required('Senha é obrigatório'),
    confirmationPassword: Yup.string()
      .required('Digite a senha novamente')
      .oneOf([Yup.ref('password')], 'As senhas devem ser iguais'),
  });

  const formik = useFormik<FormValuesProps>({
    initialValues: {
      name: '',
      nameCompany: '',
      cpf: '',
      cnpj: '',
      email: '',
      phone: '',
      license_type: '',
      license_number: '',
      password: '',
      confirmationPassword: '',
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values: FormValuesProps, { resetForm, setErrors }) => {
      try {
        const data = {
          name: values?.name,
          nameCompany: values?.nameCompany,
          email: values?.email,
          phone: values?.phone,
          cpf: values?.cpf,
          cnpj: values?.cnpj,
          license_type: values?.license_type,
          license_number: values?.license_number,
          password: values?.password,
          confirmationPassword: values?.confirmationPassword,
          role: Role.CUSTOMER,
        };

        await axiosInstance.post(apiRoutes.users.create(), data).then((data) => {
          const userCreated: Users = data.data;
          handleSetUserCreatedID(userCreated?.id || '');
          handleSetStripeCustomerID(userCreated?.external_id || '');
        });
        handleNext();
        enqueueSnackbar('Seu cadastro foi efetuado com sucesso!');
      } catch (error) {
        console.error(error);

        resetForm();

        setErrors({ afterSubmit: error.message });
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    isValid,
    values,
    dirty,
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form noValidate onSubmit={handleSubmit} style={{ width: '100%' }}>
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Grid item xs={12} sx={{ minWidth: '55vw' }}>
            <Grid container spacing={2} sx={{ marginBottom: '15px' }}>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" color={theme.palette.primary.main}>
                  Nome Completo
                </Typography>
                <TextField
                  fullWidth
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" color={theme.palette.primary.main}>
                  Nome da empresa
                </Typography>
                <TextField
                  fullWidth
                  {...getFieldProps('nameCompany')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle2" color={theme.palette.primary.main}>
                  CPF
                </Typography>
                <InputMask
                  mask="999.999.999-99"
                  onChange={(e) => {
                    setFieldValue('cpf', e.target.value);
                  }}
                >
                  <TextField
                    name="cpf"
                    type="text"
                    fullWidth
                    error={Boolean(errors.cpf)}
                    helperText={touched.cpf && errors.cpf}
                  />
                </InputMask>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle2" color={theme.palette.primary.main}>
                  CNPJ
                </Typography>
                <InputMask
                  mask="99.999.999/9999-99"
                  onChange={(e) => {
                    const cnpjValue = e.target.value.replace(/[^\d]/g, '');
                    setFieldValue('cnpj', cnpjValue);
                  }}
                >
                  <TextField
                    name="cnpj"
                    type="text"
                    fullWidth
                    error={Boolean(errors.cnpj)}
                    helperText={touched.cnpj && errors.cnpj}
                  />
                </InputMask>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Typography variant="subtitle2" color={theme.palette.primary.main}>
                  Contato
                </Typography>
                <InputMask
                  mask="(99) 99999-9999"
                  onChange={(e) => {
                    setFieldValue('phone', e.target.value);
                  }}
                >
                  <TextField
                    name="phone"
                    type="text"
                    fullWidth
                    error={Boolean(errors.phone)}
                    helperText={touched.phone && errors.phone}
                  />
                </InputMask>
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ marginBottom: '15px' }}>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" color={theme.palette.primary.main}>
                  Email
                </Typography>
                <TextField
                  autoComplete="none"
                  fullWidth
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography variant="subtitle2" color={theme.palette.primary.main}>
                  Tipo de registro profissional
                </Typography>
                <TextField
                  fullWidth
                  {...getFieldProps('license_type')}
                  error={Boolean(touched.license_type && errors.license_type)}
                  helperText={touched.license_type && errors.license_type}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Typography variant="subtitle2" color={theme.palette.primary.main}>
                  N° registro profissional
                </Typography>
                <TextField
                  fullWidth
                  {...getFieldProps('license_number')}
                  error={Boolean(touched.license_number && errors.license_number)}
                  helperText={touched.license_number && errors.license_number}
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} sx={{ marginBottom: '15px' }}>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" color={theme.palette.primary.main}>
                  Senha
                </Typography>
                <TextField
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  {...getFieldProps('password')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle2" color={theme.palette.primary.main}>
                  Confirmar senha
                </Typography>
                <TextField
                  fullWidth
                  type={showPassword ? 'text' : 'password'}
                  {...getFieldProps('confirmationPassword')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                          <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  error={Boolean(touched.confirmationPassword && errors.confirmationPassword)}
                  helperText={touched.confirmationPassword && errors.confirmationPassword}
                />
              </Grid>
            </Grid>
          </Grid>
        </Stack>
        <LoadingButton
          fullWidth
          type="submit"
          size="large"
          variant="contained"
          sx={{ mt: 3 }}
          loading={isSubmitting}
          disabled={!(isValid && dirty)}
        >
          Continuar
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
