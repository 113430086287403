import { Box, Grid, IconButton, Stack, Toolbar, Typography, useTheme } from '@mui/material';
import { FOOTER } from '../../../config';
import Logo from '../../../components/Logo';
import Iconify from 'src/components/Iconify';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';

// ----------------------------------------------------------------------

export default function Footer() {

  const [isContentOverflowing, setIsContentOverflowing] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsContentOverflowing(
        document.body.scrollHeight > window.innerHeight
      );
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const theme = useTheme();
  return (
    <Box
      sx={{
        position: isContentOverflowing ? 'relative' : '',
        bottom: 0,
        left: 0,
        width: '100%',
        px: { lg: 5 },
        backgroundColor: theme.palette.grey[200],
      }}
      pt={1}
      pb={0}
    >
      <Grid
        container
        spacing={6}
        alignItems={'center'}
        justifyContent={'center'}
        sx={{ xs: { wrap: 'wrap' }, lg: { wrap: 'nowrap' } }}
      >
        <Grid item xs={12} lg={1.5} my={'15px'}>
          <Logo color="grey" sx={{ width: '100%' }} />
        </Grid>

        <Grid item display={'flex'} flexDirection={'row'} alignItems={'center'}>
          <Typography variant="subtitle2" color={theme.palette.grey[500]} sx={{ mr: 1 }}>
            {`Siga-nos nas redes sociais`}
          </Typography>

          <a href="https://www.instagram.com/brclightning/" target="_blank">
            <IconButton>
              <Iconify
                icon="mdi:instagram"
                width={24}
                height={24}
                color={theme.palette.primary.main}
              />
            </IconButton>
          </a>

          <a
            href="https://www.linkedin.com/company/brclightning-servicos-de-software-ltda/"
            target="_blank"
          >
            <IconButton>
              <Iconify
                icon="mdi:linkedin"
                width={24}
                height={24}
                color={theme.palette.primary.main}
              />
            </IconButton>
          </a>
        </Grid>

        <Grid item>
          <a
            href="https://drive.google.com/file/d/1u2iLe9oYX22UdSve_8K57ZDJGwi5eu-5/view"
            target="_blank"
            style={{ textDecoration: 'none' }}
          >
            <Typography variant="subtitle2" color={theme.palette.primary.main}>
              {`Termos de Uso`}
            </Typography>
          </a>
        </Grid>

        <Grid item>
          <a
            href="https://drive.google.com/file/d/1qkytIWRHAlp1ELBAEtpGjaMnS9QvsMaq/view"
            target="_blank"
            style={{ textDecoration: 'none' }}
          >
            <Typography variant="subtitle2" color={theme.palette.primary.main}>
              {`Política de Privacidade`}
            </Typography>
          </a>
        </Grid>

        <Grid item>
          <Typography variant="subtitle2" color={theme.palette.grey[500]}>
            {`BRC Lghtning © ${new Date()
              .getFullYear()
              .toString()} | Todos os direitos reservados.`}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
