import { styled } from '@mui/material/styles';
import { Box, Stepper, Step, StepLabel, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import 'react-credit-cards/es/styles-compiled.css';
import { Elements } from '@stripe/react-stripe-js';
import { PaymentForm, PlanForm } from 'src/sections/auth/register';
import { loadStripe } from '@stripe/stripe-js';

const ContentStyle = styled('div')(({ theme }) => ({
  // maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}));

type RegisterSubscriptionProps = {
  user_id: string;
  user_external_id: string;
};

// ----------------------------------------------------------------------

export default function RegisterSubscription({
  user_id,
  user_external_id,
}: RegisterSubscriptionProps) {
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set<number>());
  const [stripePromise, setStripePromise] = useState<any>(null);
  const [clientSecret, setClientSecret] = useState('');
  const [isSetup, setIsSetup] = useState(false);

  const handleSetClientSecret = (value: string, isSetup: boolean) => {
    setClientSecret(value);
    setIsSetup(isSetup);
  };

  const steps = [
    {
      label: 'Plano de assinatura',
    },
    {
      label: 'Pagamento',
    },
  ];

  const isStepSkipped = (step: number) => skipped.has(step);

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handlePrevious = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

  useEffect(() => {
    setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || ''));
  }, []);
  return (
    <ContentStyle sx={{ display: 'flex', width: '100%' }}>
      <Box
        sx={{
          marginBottom: '10px',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Stepper activeStep={activeStep} sx={{ width: '500px' }}>
          {steps.map((item, index) => {
            const stepProps: { completed?: boolean } = {};
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={item.label} {...stepProps}>
                <StepLabel>{item.label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>

      <Box sx={{ display: activeStep === 0 ? 'block' : 'none', width: '100%' }}>
        <PlanForm
          activeStep={activeStep}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          customerID={user_external_id}
          userID={user_id}
          handleSetClientSecret={(value: string, isSetup: boolean) =>
            handleSetClientSecret(value, isSetup)
          }
        />
      </Box>

      <Box sx={{ display: activeStep === 1 ? 'block' : 'none', width: '100%' }}>
        {clientSecret && stripePromise ? (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <PaymentForm activeStep={activeStep} userID={user_id} isSetup={isSetup} />
          </Elements>
        ) : (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <CircularProgress size={'15px'} />
          </div>
        )}
      </Box>
    </ContentStyle>
  );
}
