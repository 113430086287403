import { Box, Stack, Toolbar } from '@mui/material';
import { HEADER } from '../../../config';
import Logo from '../../../components/Logo';
import AccountPopover from './AccountPopover';

// ----------------------------------------------------------------------

export default function DashboardHeader() {
  return (
    <div
      style={{
        height: HEADER.MOBILE_HEIGHT,
        width: '100%',
        maxWidth: '1536px',
        margin: '0 auto',
        padding: 0,
      }}
    >
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 5 },
        }}
      >
        <Logo sx={{ mr: 2.5, mt: 3 }} />

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <AccountPopover />
        </Stack>
      </Toolbar>
    </div>
  );
}
