import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import useAuth from 'src/hooks/useAuth';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Stack, Card } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, RHFTextField } from '../../../../components/hook-form';
import { axiosInstance } from 'src/utils/axios';

// ----------------------------------------------------------------------

type FormValuesProps = {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

export default function AccountChangePassword() {
  const { user } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const ChangePassWordSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Digite a senha atual'),
    newPassword: Yup.string()
      .matches(/.*\d/, 'A senha deve conter pelo menos um dígito (0-9).')
      .matches(/.*\W+/, 'A senha deve conter pelo menos um caractere não alfanumérico.')
      .test(
        'no-dot-or-newline',
        'A senha não deve conter um ponto ou quebra de linha.',
        (value) => {
          return value !== null && value !== undefined && !/[.\n]/.test(value);
        }
      )
      .matches(/.*[A-Z]/, 'A senha deve conter pelo menos uma letra maiúscula.')
      .matches(/.*[a-z]/, 'A senha deve conter pelo menos uma letra minúscula.')
      .required('Digite a nova senha'),
    confirmNewPassword: Yup.string().oneOf(
      [Yup.ref('newPassword'), null],
      'As senhas devem ser iguais'
    ),
  });

  const defaultValues = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const methods = useForm({
    resolver: yupResolver(ChangePassWordSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await axiosInstance.post(`/users/verify-oldPassword/${user?.id}`, {
        oldPassword: data.oldPassword,
      });
      await axiosInstance.put(`/users/updatePassword/${user?.id}`, {
        newPassword: data.newPassword,
      });
      reset();
      enqueueSnackbar('Senha Atualizada com sucesso!');
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      console.error(error);
    }
  };

  return (
    <Card sx={{ p: 3 }}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} alignItems="flex-end">
          <RHFTextField name="oldPassword" type="password" label="Senha atual" />

          <RHFTextField name="newPassword" type="password" label="Nova senha" />

          <RHFTextField name="confirmNewPassword" type="password" label="Confirmar senha" />

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Salvar
          </LoadingButton>
        </Stack>
      </FormProvider>
    </Card>
  );
}
