import { useState, ReactNode, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { UserPlanStatus } from 'src/@types/localEnum';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { axiosInstance } from 'src/utils/axios';
import { Subscription } from 'src/@types/localEntity';

// ----------------------------------------------------------------------

type PaymentGuardProps = {
  children: ReactNode;
};

export default function PaymentGuard({ children }: PaymentGuardProps) {
  const { user } = useAuth();
  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const [subscriptionStripe, setSubscriptionStripe] = useState('');
  const [loading, setLoading] = useState(true);

  const getSubscription = async () => {
    const getSubscription = await axiosInstance.get(`subscription/get/${user?.id}`);
    if (!!getSubscription.data) {
      setSubscription(getSubscription.data);
    } else {
      setLoading(false);
    }
  };
  const getSubscriptionStripe = async () => {
    const getSubscriptionStripe = await axiosInstance.get(
      `subscription/getSubscription/stripe/${subscription?.external_id}`
    );
    setSubscriptionStripe(getSubscriptionStripe.data.status);

    setLoading(false);

    if (
      getSubscriptionStripe.data.status === 'canceled' &&
      subscription?.plan_status === UserPlanStatus.PAGO
    ) {
      await axiosInstance.get(`subscription/canceledSubscription/${subscription.id}`);
    }
  };

  useEffect(() => {
    getSubscription();
  }, []);
  useEffect(() => {
    if (!!subscription) {
      getSubscriptionStripe();
    }
  }, [subscription]);

  if (!loading) {
    if (
      (user?.role != 'ADMIN' && !subscription?.plan_status) ||
      subscriptionStripe === 'canceled'
    ) {
      let state: any = { no_sub_modal: true };

      return <Navigate to={PATH_DASHBOARD.general.account} state={state} />;
    }

    if (
      user?.role != 'ADMIN' &&
      subscription?.plan_status &&
      subscription?.plan_status !== UserPlanStatus.PAGO &&
      subscription?.plan_status !== UserPlanStatus.TRIAL &&
      subscription.plan_status !== UserPlanStatus.CANCELADO &&
      subscription.active &&
      subscriptionStripe != 'canceled'
    ) {
      let state: any = { payment_modal: true };

      return <Navigate to={PATH_DASHBOARD.general.account} state={state} />;
    }
  }

  return <>{children}</>;
}
