import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Link, Typography, Button, useTheme } from '@mui/material';
import { PATH_AUTH } from '../../routes/paths';
import useResponsive from '../../hooks/useResponsive';
import Page from '../../components/Page';
import Image from '../../components/Image';
import { AdminLoginForm } from 'src/sections/auth/admin-login';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    height: '100vh',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'flex-start',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(5, 5, 0, 2),
    position: 'relative',
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxHeight: '870px',
  minHeight: '80%',
  height: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  padding: '30px',
  borderRadius: '16px 0 0 16px',
}));

const ContentStyle = styled('div')(({ theme }) => ({
  width: '50%',
  maxWidth: '40%',
  margin: 'auto',
  maxHeight: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
  [theme.breakpoints.up('md')]: {
    width: '50%',
    margin: 0,
  },
}));

const SectionContainer = styled('div')(({ theme }) => ({
  height: '100%',
}));
const ShadowContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    padding: '5vh 2vw',
    margin: 'auto',
    width: '70vw',
    maxWidth: '1250px',
    height: '90vh',
    maxHeight: '780px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '75px',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;',
    borderRadius: '20px',
  },
}));

// ----------------------------------------------------------------------

export default function AdminLogin() {
  const mdUp = useResponsive('up', 'md');
  const theme = useTheme();

  return (
    <Page title="Login">
      <RootStyle>
        <ShadowContainer>
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
              <Box sx={{ flexGrow: 0.5 }}>
                <Typography variant="h4" gutterBottom>
                  Seja muito bem-vindo(a)!
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  Confirme sua senha para continuar.
                </Typography>
              </Box>
            </Stack>

            <AdminLoginForm />
          </ContentStyle>
        </ShadowContainer>
      </RootStyle>
    </Page>
  );
}
