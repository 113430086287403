import * as Yup from 'yup';
import { useState } from 'react';
// form
// @mui
import { Stack, Alert, IconButton, InputAdornment, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import { useFormik, FormikProvider, Form } from 'formik';

// ----------------------------------------------------------------------

type FormValuesProps = {
  email: string;
  password: string;
  afterSubmit?: string;
};

export default function LoginForm() {
  const { login } = useAuth();

  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email inválido').required('Email é obrigatório'),
    password: Yup.string().required('Senha é obrigatória'),
  });

  const formik = useFormik<FormValuesProps>({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: LoginSchema,
    onSubmit: async (values: FormValuesProps, { resetForm, setErrors }) => {
      try {
        await login(values.email, values.password);
      } catch (error) {
        console.error(error);

        resetForm();

        setErrors({ afterSubmit: error.message })
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik

  // const {
  //   reset,
  //   setError,
  //   handleSubmit,
  //   formState: { errors, isSubmitting, isValidating },
  // } = methods;

  return (
    <FormikProvider value={formik}>
      <Form noValidate onSubmit={handleSubmit}>
        <Stack spacing={2}>
          {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

          <TextField 
            label="Email" 
            {...getFieldProps('email')}
            error={
              Boolean(touched.email && errors.email)
            }
            helperText={
              (touched.email && errors.email)
            }
          />

          <TextField
            label="Senha"
            type={showPassword ? 'text' : 'password'}
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={
              Boolean(touched.password && errors.password)
            }
            helperText={
              (touched.password && errors.password)
            }
          />
        </Stack>

        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
            Forgot password?
          </Link>
        </Stack> */}

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          sx={{ mt: 3 }}
        >
          Entrar
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
