import * as Yup from 'yup';
import { Box, Grid, Card, Stack, TextField, Button, ButtonBase, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useAuth from '../../../../hooks/useAuth';
import { useFormik, FormikProvider, Form } from 'formik';
import { axiosInstance } from 'src/utils/axios';
import { apiRoutes } from 'src/routes/api';
import { useEffect, useState } from 'react';
import { Users } from 'src/@types/localEntity';
import { cpfMask, telMask, cnpjMask } from 'src/utils/masks';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { useSnackbar } from 'notistack';

import Image from '../../../../components/Image';
import palette from 'src/theme/palette';
import Iconify from 'src/components/Iconify';
import { set } from 'lodash';
import { profile } from 'console';
import { HOST_API } from 'src/config';
import axios from 'axios';
import MyAvatar from 'src/components/MyAvatar';

// ----------------------------------------------------------------------

type FormValuesProps = {
  name: string;
  email: string;
  phone: string;
  cpf: string;
  license_type: string;
  license_number: string;
  nameCompany: string;
  cnpj: string;
};

export default function AccountGeneral() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const [currentUser, setCurrentUser] = useState<Users>();

  const queryData = async () => {
    const getUser = await axiosInstance.get(apiRoutes.users.readUnique(user?.id || ''));

    setCurrentUser(getUser.data);
  };

  useEffect(() => {
    if (currentUser) {
      resetForm();
    } else {
      queryData();
    }
  }, [user, currentUser]);

  const UpdateUserSchema = Yup.object().shape({
    name: Yup.string().required('Campo obrigatório'),
    email: Yup.string().required('Campo obrigatório').email(),
    phone: Yup.string().required('Campo obrigatório'),
    cpf: Yup.string().required('Campo obrigatório'),
    license_type: Yup.string().required('Campo obrigatório'),
    license_number: Yup.string().required('Campo obrigatório'),
    nameCompany: Yup.string().required('Campo obrigatório'),
    cnpj: Yup.string().required('Campo obrigatório'),
  });

  const formik = useFormik<FormValuesProps>({
    enableReinitialize: true,
    initialValues: {
      name: currentUser?.name || '',
      email: currentUser?.email || '',
      phone: currentUser?.phone || '',
      cpf: currentUser?.cpf || '',
      license_type: currentUser?.license_type || '',
      license_number: currentUser?.license_number || '',
      nameCompany: currentUser?.nameCompany || '',
      cnpj: currentUser?.cnpj || '',
    },
    validationSchema: UpdateUserSchema,
    onSubmit: async (values: FormValuesProps, { resetForm, setErrors }) => {
      const formData = new FormData();

      formData.append('name', values.name);
      formData.append('email', values.email);
      formData.append('phone', values.phone);
      formData.append('cpf', values.cpf);
      formData.append('license_type', values.license_type);
      formData.append('license_number', values.license_number);
      formData.append('nameCompany', values.nameCompany);
      formData.append('cnpj', values.cnpj);

      await axiosInstance.put(apiRoutes.users.update(user?.id || ''), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      await axiosInstance
        .put(apiRoutes.users.update(user?.id || ''), {
          ...values,
        })
        .then(() => {
          navigate(PATH_DASHBOARD.general.projects, { replace: true });
          enqueueSnackbar('Alterado com sucesso!', { variant: 'success' });
        })
        .catch((error) => {
          console.error(error);

          resetForm();
          enqueueSnackbar(error.message, { variant: 'success' });
          // setErrors({ afterSubmit: error.message })
        });
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    handleSubmit,
    getFieldProps,
    isValid,
    dirty,
    setFieldValue,
    resetForm,
  } = formik;

  const [imagem, setImagem] = useState<File | null>(null);

  const [imageUrl, setImageUrl] = useState(`${HOST_API}${apiRoutes.users.logo(user?.id || '')}`);
  const [reloadKey, setReloadKey] = useState(0);

  const handleReloadImage = () => {
    setReloadKey(reloadKey + 1);

    setImageUrl(`${HOST_API}${apiRoutes.users.logo(user?.id || '')}?reload=${reloadKey}`);
  };

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const arquivoSelecionado = event.target.files?.[0];

    if (arquivoSelecionado) {
      setImagem(arquivoSelecionado);

      const formData = new FormData();
      formData.append('file', arquivoSelecionado);

      var fileSize = arquivoSelecionado.size; // Tamanho do arquivo em bytes
      var maxSize = 2 * 1024 * 1024;
      const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
      if (fileSize > maxSize) {
        enqueueSnackbar('O tamanho máximo permitido para a imagem é de 2 MB.', {
          variant: 'error',
        });
        return;
      }

      if (!allowedTypes.includes(arquivoSelecionado.type)) {
        enqueueSnackbar(
          'Tipo de arquivo não suportado. Por favor, selecione uma imagem JPEG, JPG ou PNG.',
          {
            variant: 'error',
          }
        );
        return;
      }

      await axiosInstance
        .post(apiRoutes.users.uploadLogo(user?.id || ''), formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((data) => {
          enqueueSnackbar('Imagem alterada com sucesso!', { variant: 'success' });
          // handleReloadImage();
          window.location.reload();
        })
        .catch((e) => {
          enqueueSnackbar(e.message, { variant: 'error' });
        });
    }
  };

  const [hover, setHover] = useState(false);

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Box
                sx={{
                  display: 'grid',
                  rowGap: 3,
                  columnGap: 2,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                }}
              >
                <TextField
                  label="Nome"
                  {...getFieldProps('name')}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                />
                <TextField
                  label="Nome da empresa"
                  {...getFieldProps('nameCompany')}
                  error={Boolean(touched.nameCompany && errors.nameCompany)}
                  helperText={touched.nameCompany && errors.nameCompany}
                />
                <TextField
                  label="Email"
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
                <TextField
                  label="CPF"
                  {...getFieldProps('cpf')}
                  onChange={(e) => {
                    setFieldValue('cpf', cpfMask(e.target.value));
                  }}
                  error={Boolean(touched.cpf && errors.cpf)}
                  helperText={touched.cpf && errors.cpf}
                />
                <TextField
                  label="CNPJ"
                  {...getFieldProps('cnpj')}
                  onChange={(e) => {
                    setFieldValue('cnpj', cnpjMask(e.target.value));
                  }}
                  error={Boolean(touched.cnpj && errors.cnpj)}
                  helperText={touched.cnpj && errors.cnpj}
                />
                <TextField
                  label="Telefone"
                  {...getFieldProps('phone')}
                  onChange={(e) => {
                    setFieldValue('phone', telMask(e.target.value));
                  }}
                  error={Boolean(touched.phone && errors.phone)}
                  helperText={touched.phone && errors.phone}
                />
                <TextField
                  label="Tipo de Licença"
                  {...getFieldProps('license_type')}
                  error={Boolean(touched.license_type && errors.license_type)}
                  helperText={touched.license_type && errors.license_type}
                />
                <TextField
                  label="Número da Licença"
                  {...getFieldProps('license_number')}
                  error={Boolean(touched.license_number && errors.license_number)}
                  helperText={touched.license_number && errors.license_number}
                />
              </Box>

              <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  disabled={!(isValid && dirty)}
                  loading={isSubmitting}
                >
                  Salvar
                </LoadingButton>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card
              sx={{
                p: 5,
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <ButtonBase
                component="label"
                sx={{
                  marginBottom: '10px',
                  width: '144px',
                  height: '144px',
                  border: '1px dashed #aaaaaa',
                  borderRadius: '50%',
                  overflow: 'hidden',

                  backgroundColor: 'rgba(200, 200, 200, 0.5)',
                }}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                {currentUser?.s3_image_url ? (
                  <Image
                    src={imageUrl}
                    alt="Perfil"
                    style={{
                      objectFit: 'cover',
                      minWidth: '144px',
                      minHeight: '144px',
                    }}
                  />
                ) : (
                  <MyAvatar sx={{ width: '144px', height: '144px' }} />
                )}
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="raised-button-file"
                  multiple
                  type="file"
                  onChange={handleFileChange}
                />
                {hover && (
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      position: 'absolute',
                      borderRadius: '50%',
                      justifyContent: 'center',
                      flexDirection: 'column',
                      alignItems: 'center',
                      display: 'flex',
                      backgroundColor: 'rgba(200, 200, 200, 0.5)',
                    }}
                  >
                    <Iconify icon={'ic:outline-add-a-photo'} sx={{ fontSize: 30 }} />
                    <Typography
                      variant="caption"
                      fontWeight={400}
                      display={'block'}
                      mt={0.2}
                    >{`Atualizar Foto`}</Typography>
                  </Box>
                )}
              </ButtonBase>
              <Typography
                variant="caption"
                color={palette.light.grey[600]}
                fontWeight={400}
                display={'block'}
              >{`Permitido *.jpeg, *.jpg, *.png`}</Typography>

              <Typography
                variant="caption"
                color={palette.light.grey[600]}
                fontWeight={400}
                display={'block'}
              >{`Tamanho máximo de 2 MB`}</Typography>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
