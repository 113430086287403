import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import { PATH_AFTER_LOGIN } from '../config';
import LoadingScreen from '../components/LoadingScreen';
import PaymentGuard from 'src/guards/PaymentGuard';
import { PATH_DASHBOARD } from './paths';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import UserAccount from 'src/pages/dashboard/UserAccount';
import AdminGuestGuard from 'src/guards/AdminGuestGuard';
import AdminGuard from 'src/guards/AdminGuard';
import ParallelCalcReport from 'src/pages/dashboard/management/projects/modules/reports/parallelCalcReport';
// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '');

  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'admin-login/:clientId/:email',
          element: (
            <AdminGuestGuard>
              <AdminLogin />
            </AdminGuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Elements stripe={stripePromise}>
                <Register />
              </Elements>
            </GuestGuard>
          ),
        },
        { path: 'payment-confirmed/:id', element: <PaymentConfirmed /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password/:id/:token', element: <NewPassword /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <PaymentGuard>
            <DashboardLayout />
          </PaymentGuard>
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'projects', element: <ProjectsApp /> },
        { path: 'project-modules/:id', element: <ProjectsModule /> },
        { path: 'project-modules/:id/spheres-module', element: <SpheresModule /> },
        { path: 'project-modules/:id/spheres-module/coverage-calc/', element: <CoverageCalc /> },
        {
          path: 'project-modules/:id/spheres-module/coverage-calc/:calcId',
          element: <CoverageCalc />,
        },
        {
          path: 'project-modules/:id/spheres-module/isolated-spda-calc/',
          element: <IsolatedSpdaCalc />,
        },
        {
          path: 'project-modules/:id/spheres-module/isolated-spda-calc/:calcId',
          element: <IsolatedSpdaCalc />,
        },
        {
          path: 'project-modules/:id/spheres-module/parallel-spda-calc/',
          element: <ParallelSpdaCalc />,
        },
        {
          path: 'project-modules/:id/spheres-module/parallel-spda-calc/:calcId',
          element: <ParallelSpdaCalc />,
        },
        { path: 'project-modules/:id/spheres-module/limits-calc/', element: <LimitsCalc /> },
        { path: 'project-modules/:id/spheres-module/limits-calc/:calcId', element: <LimitsCalc /> },
        // { path: 'account', element: <UserAccount /> },

        { path: 'project-modules/:id/materials-module', element: <MaterialsModule /> },

        {
          path: 'project-modules/:id/materials-module/item-lists/',
          element: <ItemList />,
        },

        {
          path: 'project-modules/:id/materials-module/item-lists/editBundles/:itemListId',
          element: <EditItemListBundles />,
        },
        {
          path: 'project-modules/:id/materials-module/item-lists/editBundles',
          element: <EditItemListBundles />,
        },

        {
          path: 'project-modules/:id/materials-module/item-lists/editItems/:itemListId',
          element: <EditItemListItem />,
        },
        {
          path: 'project-modules/:id/materials-module/item-lists/editItems/',
          element: <EditItemListItem />,
        },

        {
          path: 'project-modules/:id/materials-module/item-lists/:itemListId',
          element: <ItemList />,
        },

        { path: 'permission-denied', element: <PermissionDenied /> },
      ],
    },

    // Admin Routes

    {
      path: 'dashboard',
      element: (
        <AdminGuard>
          <DashboardLayout />
        </AdminGuard>
      ),
      children: [
        { path: 'projects/:clientId', element: <ProjectsApp /> },
        { path: 'projects/:clientId/:email', element: <ProjectsApp /> },
      ],
    },

    // Account Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_DASHBOARD.general.account} replace /> },
        { path: 'account', element: <UserAccount /> },
      ],
    },

    // Report Routes

    {
      path: 'report',
      children: [
        { path: 'coverage-calc', element: <CoverageCalcReport /> },
        { path: 'isolated-calc', element: <IsolatedCalcReport /> },
        { path: 'limits-calc', element: <LimitsCalcReport /> },
        { path: 'parallel-calc', element: <ParallelCalcReport /> },

        { path: 'materials/items', element: <MaterialsItemsReport /> },
        { path: 'materials/bundles', element: <MaterialsBundlesReport /> },
        { path: 'materials/general', element: <MaterialGeneralsReport /> },
        { path: 'materials/groups', element: <MaterialGroupsReport /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const AdminLogin = Loadable(lazy(() => import('../pages/auth/AdminLogin')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const PaymentConfirmed = Loadable(lazy(() => import('../pages/auth/PaymentConfirmed')));

// DASHBOARD

// REPORT
const CoverageCalcReport = Loadable(
  lazy(() => import('../pages/dashboard/management/projects/modules/reports/CoverageCalcReport'))
);
const IsolatedCalcReport = Loadable(
  lazy(() => import('../pages/dashboard/management/projects/modules/reports/IsolatedCalcReport'))
);
const LimitsCalcReport = Loadable(
  lazy(() => import('../pages/dashboard/management/projects/modules/reports/LimitsCalcReport'))
);

const MaterialsItemsReport = Loadable(
  lazy(() => import('../pages/dashboard/management/projects/modules/reports/MaterialsItemsReport'))
);
const MaterialsBundlesReport = Loadable(
  lazy(
    () => import('../pages/dashboard/management/projects/modules/reports/MaterialsBundlesReport')
  )
);
const MaterialGeneralsReport = Loadable(
  lazy(
    () => import('../pages/dashboard/management/projects/modules/reports/MaterialsGeneralReport')
  )
);
const MaterialGroupsReport = Loadable(
  lazy(() => import('../pages/dashboard/management/projects/modules/reports/MaterialsGroupsReport'))
);

// GENERAL
const ProjectsApp = Loadable(lazy(() => import('../pages/dashboard/management/projects/Projects')));
const ProjectsModule = Loadable(
  lazy(() => import('../pages/dashboard/management/projects/ProjectsModule'))
);

// MODULES
const SpheresModule = Loadable(
  lazy(() => import('../pages/dashboard/management/projects/modules/Spheres'))
);

const MaterialsModule = Loadable(
  lazy(() => import('../pages/dashboard/management/projects/modules/Materials'))
);

// CALCS
const CoverageCalc = Loadable(
  lazy(() => import('../pages/dashboard/management/projects/modules/calcs/CoverageCalc'))
);
const IsolatedSpdaCalc = Loadable(
  lazy(() => import('../pages/dashboard/management/projects/modules/calcs/IsolatedSpdaCalc'))
);
const LimitsCalc = Loadable(
  lazy(() => import('../pages/dashboard/management/projects/modules/calcs/LimitsCalc'))
);
const ParallelSpdaCalc = Loadable(
  lazy(() => import('../pages/dashboard/management/projects/modules/calcs/ParallelSpdaCalc'))
);

// ITEM LISTS
const ItemList = Loadable(
  lazy(() => import('../pages/dashboard/management/projects/modules/itemLists/ItemList'))
);

const EditItemListBundles = Loadable(
  lazy(() => import('../pages/dashboard/management/projects/modules/itemLists/editItemListBundles'))
);

const EditItemListItem = Loadable(
  lazy(() => import('../pages/dashboard/management/projects/modules/itemLists/editItemListItems'))
);

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionDenied')));

// MAIN
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
