// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_REPORT = '/report';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  adminLogin: path(ROOTS_AUTH, '/admin-login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    projects: path(ROOTS_DASHBOARD, '/projects'),
    adminProjects: (clientId: string) => path(ROOTS_DASHBOARD, `/projects/${clientId}`),
    projectModule: (id: string) => path(ROOTS_DASHBOARD, `/project-modules/${id}`),
    spheresModule: (id: string) => path(ROOTS_DASHBOARD, `/project-modules/${id}/spheres-module`),

    calc: {
      coverageCalc: (projectId: string, calcId: string, view: boolean = false) =>
        path(
          ROOTS_DASHBOARD,
          `/project-modules/${projectId}/spheres-module/coverage-calc/${calcId}?view=${view}`
        ),
      isolatedSpdaCalc: (projectId: string, calcId: string, view: boolean = false) =>
        path(
          ROOTS_DASHBOARD,
          `/project-modules/${projectId}/spheres-module/isolated-spda-calc/${calcId}?view=${view}`
        ),
      limitsCalc: (projectId: string, calcId: string, view: boolean = false) =>
        path(
          ROOTS_DASHBOARD,
          `/project-modules/${projectId}/spheres-module/limits-calc/${calcId}?view=${view}`
        ),
      parallelCalc: (projectId: string, calcId: string, view: boolean = false) =>
        path(
          ROOTS_DASHBOARD,
          `/project-modules/${projectId}/spheres-module/parallel-spda-calc/${calcId}?view=${view}`
        ),
    },

    materialsModule: (id: string) =>
      path(ROOTS_DASHBOARD, `/project-modules/${id}/materials-module`),

    itemList: (projectId: string, itemListId: string, view: boolean = false) =>
      path(
        ROOTS_DASHBOARD,
        `/project-modules/${projectId}/materials-module/item-lists/${itemListId}?view=${view}`
      ),
    editItemListBundles: (projectId: string, itemListId: string) =>
      path(
        ROOTS_DASHBOARD,
        `/project-modules/${projectId}/materials-module/item-lists/editBundles/${itemListId}`
      ),
    editItemListItems: (projectId: string, itemListId: string) =>
      path(
        ROOTS_DASHBOARD,
        `/project-modules/${projectId}/materials-module/item-lists/editItems/${itemListId}`
      ),

    account: path(ROOTS_DASHBOARD, '/account'),
  },
  management: {
    users: path(ROOTS_DASHBOARD, '/management/users'),
    createUsers: path(ROOTS_DASHBOARD, '/management/users/create'),
    editUser: (id: string) => path(ROOTS_DASHBOARD, `/management/users/${id}/edit`),
  },
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    edit: (name: string) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
  },
};

export const PATH_REPORT = {
  root: ROOTS_REPORT,
  spheres: {
    coverageCalc: path(ROOTS_REPORT, '/coverage-calc'),
    isolatedCalc: path(ROOTS_REPORT, '/isolated-calc'),
    limitsCalc: path(ROOTS_REPORT, '/limits-calc'),
    parallelCalc: path(ROOTS_REPORT, '/parallel-calc'),
  },
  materials: {
    items: path(ROOTS_REPORT, '/materials/items'),
    bundles: path(ROOTS_REPORT, '/materials/bundles'),
    general: path(ROOTS_REPORT, '/materials/general'),
    groups: path(ROOTS_REPORT, '/materials/groups'),
  },
};
