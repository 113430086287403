import { capitalCase } from 'change-case';
// @mui
import { Container, Tab, Box, Tabs, Button } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useTabs from '../../hooks/useTabs';
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
// sections
import { AccountGeneral, AccountChangePassword } from '../../sections/@dashboard/user/account';
import { useLocation } from 'react-router-dom';
import AccountFinancial from 'src/sections/@dashboard/user/account/AccountFinancial';
import { useState } from 'react';
import { ModalDeactivateAccount } from 'src/sections/@dashboard/user/account/AccountModals/ModalDeactivateAccount';

type StateProps = {
  payment_modal?: boolean;
  no_sub_modal?: boolean;
};

// ----------------------------------------------------------------------

export default function UserAccount() {
  const { themeStretch } = useSettings();
  const { state } = useLocation();

  const { currentTab, onChangeTab } = useTabs(state ? 'Pagamento' : 'Geral');

  const [deactivateAccountModal, setDeactivateAccountModal] = useState(false);
  const openDeactivateAccountModal = () => setDeactivateAccountModal(true);
  const closeDeactivateAccountModal = () => setDeactivateAccountModal(false);

  const ACCOUNT_TABS = [
    {
      value: 'Geral',
      icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />,
      component: <AccountGeneral />,
    },
    {
      value: 'Pagamento',
      icon: <Iconify icon={'material-symbols:attach-money-rounded'} width={20} height={20} />,
      component: <AccountFinancial propState={state as StateProps} />,
    },
    {
      value: 'Alterar senha',
      icon: <Iconify icon={'ic:round-vpn-key'} width={20} height={20} />,
      component: <AccountChangePassword />,
    },
  ];

  return (
    <Page title="Minha conta">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Minha Conta"
          links={[{ name: 'Início', href: PATH_DASHBOARD.root }, { name: 'Conta' }]}
        />

        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={currentTab}
          onChange={onChangeTab}
        >
          {ACCOUNT_TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={capitalCase(tab.value)}
              icon={tab.icon}
              value={tab.value}
            />
          ))}

          <Button color={'error'} onClick={openDeactivateAccountModal}>
            <Iconify icon={'eva:trash-2-outline'} />
            Excluir Conta
          </Button>
        </Tabs>

        <Box sx={{ mb: 5 }} />

        {ACCOUNT_TABS.map((tab) => {
          const isMatched = tab.value === currentTab;
          return isMatched && <Box key={tab.value}>{tab.component}</Box>;
        })}
      </Container>
      <Box>
        <ModalDeactivateAccount
          open={deactivateAccountModal}
          handleCloseModal={closeDeactivateAccountModal}
        />
      </Box>
    </Page>
  );
}
