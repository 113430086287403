import React, { useEffect, useState } from 'react';
import { Alert, AlertTitle, Box, Button, Card, Modal, Typography } from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import { axiosInstance } from 'src/utils/axios';
import { Subscription } from 'src/@types/localEntity';

type StateProps = {
  payment_modal?: boolean;
  no_sub_modal?: boolean;
};

type ModalSubscriptionAlertProps = {
  open: boolean;
  handleCloseModal: () => void;
  type: StateProps | null;
};

export const ModalSubscriptionAlert = ({
  open,
  handleCloseModal,
  type,
}: ModalSubscriptionAlertProps) => {
  const { user } = useAuth();

  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const [loading, setLoading] = useState(true);

  const getSubscription = async () => {
    const subscription = await axiosInstance.get(`subscription/get/${user?.id}`);
    setSubscription(subscription.data);
    setLoading(false);
  };

  useEffect(() => {
    getSubscription();
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Card
        sx={{
          minHeight: '300px',
          maxWidth: '500px',
        }}
      >
        <Alert severity="warning">
          <AlertTitle>
            {type?.payment_modal
              ? `Pagamento ${subscription?.plan_status}!`
              : `Assinatura necessária!`}
          </AlertTitle>
          {type?.payment_modal
            ? `O pagamento do seu plano está ${subscription?.plan_status
                .toString()
                .toLowerCase()} e deve ser revisado.`
            : `Você não tem uma assinatura ativa no momento!`}
        </Alert>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '20px',
            gap: '30px',
          }}
        >
          <Box sx={{ maxWidth: '75%', textAlign: 'center' }}>
            <Typography sx={{ fontWeight: 'bold' }}>
              {type?.payment_modal
                ? `A sua assinatura pode estar atrasada, pendente ou finalizada no momento!`
                : `Sua conta ainda não está vinculada a um plano de assinatura no momento`}
            </Typography>
            <Typography>
              Desta forma, por enquanto o acesso total ao sistema não pode ser liberado até que
              esteja tudo certo com seu plano de assinatura!
            </Typography>
          </Box>
          <Button variant="contained" onClick={handleCloseModal}>
            Entendi
          </Button>
        </Box>
      </Card>
    </Modal>
  );
};
