export function cnpjMask(cnpj: string) {
  cnpj = cnpj.replace(/\D/g,"")
  cnpj = cnpj.replace(/(\d{2})(\d)/,"$1.$2")
  cnpj = cnpj.replace(/(\d{3})(\d)/,"$1.$2")
  cnpj = cnpj.replace(/(\d{3})(\d)/,"$1/$2")
  cnpj = cnpj.replace(/(\d{4})(\d)/,"$1-$2")
  return cnpj;
  //return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
}

export function cepMask(cep: string) {
  return cep.replace(/^(\d{5})(\d)/, '$1-$2');
}

export function plateMask(cep: string) {
  return cep.replace(/^([\D]{3})([\d][0-9A-Z][\d]{2})/, '$1-$2');
}

export function expirationMask(cep: string) {
  return cep.replace(/^(\d{2})(\d)/, '$1/$2');
}

export function telMask(tel: string) {
  tel = tel.replace(/\D/g, '');
  tel = tel.replace(/^(\d{2})(\d)/g, '($1) $2');
  tel = tel.replace(/(\d)(\d{4})$/, '$1-$2');
  return tel;
}

export function cpfMask(cpf: string) {
  cpf = cpf.replace(/\D/g,"")
  cpf = cpf.replace(/(\d{3})(\d)/,"$1.$2")
  cpf = cpf.replace(/(\d{3})(\d)/,"$1.$2")
  cpf = cpf.replace(/(\d{3})(\d{1,2})$/,"$1-$2")
  return cpf
}

export function cardNumberMask(number: string) {
  number = number.replace(/\D/g,"")
  number = number.replace(/(\d{4})(\d)/,"$1 $2")
  number = number.replace(/(\d{4})(\d)/,"$1 $2")
  number = number.replace(/(\d{4})(\d)/,"$1 $2")
  return number
}