import { group } from 'console';

const routes = {
  auth: '/auth',
  users: '/users',
  projects: 'projects',
  spdas: '/spdas',
  spdasTypes: '/spda-type',
  structures: '/structures',
  viewcuts: '/viewcuts',
  calcs: '/calcs',
  parallelCalcs: '/parallel-calcs',
  norms: '/norms',
  projectClasses: '/project-classes',
  dimensioningTypes: '/dimensioning-types',
  plans: '/plans',
  payment: '/payment',
  subscriptions: '/subscription',

  items: '/items',
  itemList: '/item-list',
  itemBundles: '/item-bundles',
};

const auth = {
  login: () => routes.auth + '/login',
  adminlogin: () => routes.auth + '/login-admin',
  account: () => routes.auth + '/account',
  changePassword: (id: string) => `${routes.auth}/change-password/${id}`,
  resetPassword: routes.auth + '/reset-password/',
  newPassword: routes.auth + '/new-password/',
};

const users = {
  read: () => `${routes.users}`,
  readUnique: (id: string) => `${routes.users}/${id}`,
  create: () => `${routes.users}`,
  delete: (id: string) => `${routes.users}/${id}`,
  deactivate: (id: string) => `${routes.users}/deactivate/${id}`,
  update: (id: string) => `${routes.users}/${id}`,

  uploadLogo: (id: string) => `${routes.users}/uploadLogo/${id}`,
  logo: (id: string) => `${routes.users}/logo/${id}`,
  logoSize: (id: string) => `${routes.users}/logoSize/${id}`,
};
const plans = {
  read: () => `${routes.plans}`,
  readUnique: (id: string) => `${routes.plans}/${id}`,
  create: () => `${routes.plans}`,
  delete: (id: string) => `${routes.plans}/${id}`,
  update: (id: string) => `${routes.plans}/${id}`,
};

const projects = {
  read: () => `${routes.projects}`,
  readUnique: (id: string) => `${routes.projects}/${id}`,
  create: () => `${routes.projects}`,
  delete: (id: string) => `${routes.projects}/${id}`,
  update: (id: string) => `${routes.projects}/${id}`,
  count: (id: string) => `${routes.projects}/count/${id}`,
};

const spdas = {
  read: () => `${routes.spdas}`,
  readUnique: (id: string) => `${routes.spdas}/${id}`,
  create: () => `${routes.spdas}`,
  delete: (id: string) => `${routes.spdas}/${id}`,
  update: (id: string) => `${routes.spdas}/${id}`,
};

const spdasTypes = {
  read: () => `${routes.spdasTypes}`,
  readUnique: (id: string) => `${routes.spdasTypes}/${id}`,
  create: () => `${routes.spdasTypes}`,
  delete: (id: string) => `${routes.spdasTypes}/${id}`,
  update: (id: string) => `${routes.spdasTypes}/${id}`,
};

const structures = {
  read: () => `${routes.structures}`,
  readUnique: (id: string) => `${routes.structures}/${id}`,
  create: () => `${routes.structures}`,
  delete: (id: string) => `${routes.structures}/${id}`,
  update: (id: string) => `${routes.structures}/${id}`,
};

const viewCuts = {
  read: () => `${routes.viewcuts}`,
  readUnique: (id: string) => `${routes.viewcuts}/${id}`,
  create: () => `${routes.viewcuts}`,
  delete: (id: string) => `${routes.viewcuts}/${id}`,
  update: (id: string) => `${routes.viewcuts}/${id}`,
};

const calcs = {
  read: () => `${routes.calcs}`,
  readUnique: (id: string) => `${routes.calcs}/${id}`,
  readVersions: (id: string) => `${routes.calcs}/find-versions/${id}`,
  create: () => `${routes.calcs}`,
  delete: (id: string) => `${routes.calcs}/${id}`,
  deleteGroup: (id: string) => `${routes.calcs}/delete-group/${id}`,
  update: () => `${routes.calcs}`,
  reportList: () => `${routes.calcs}/report-list`,
  calculate: () => `${routes.calcs}/calculate`,
  duplicate: (id: string) => `${routes.calcs}/duplicate/${id}`,
};

const parallelCalcs = {
  read: () => `${routes.parallelCalcs}`,
  readUnique: (id: string) => `${routes.parallelCalcs}/find-one/${id}`,
  readVersions: (id: string) => `${routes.parallelCalcs}/find-versions/${id}`,
  save: () => `${routes.parallelCalcs}`,
  update: () => `${routes.parallelCalcs}`,
  reportList: () => `${routes.parallelCalcs}/report-list`,
  delete: (id: string) => `${routes.parallelCalcs}/${id}`,
  deleteGroup: (id: string) => `${routes.parallelCalcs}/delete-group/${id}`,
  calculate: () => `${routes.parallelCalcs}/calculate`,
  duplicate: (id: string) => `${routes.parallelCalcs}/duplicate/${id}`,
};

const norms = {
  read: () => `${routes.norms}`,
  readUnique: (id: string) => `${routes.norms}/${id}`,
  create: () => `${routes.norms}`,
  delete: (id: string) => `${routes.norms}/${id}`,
  update: (id: string) => `${routes.norms}/${id}`,
};

const projectClasses = {
  read: () => `${routes.projectClasses}`,
  readUnique: (id: string) => `${routes.projectClasses}/${id}`,
  create: () => `${routes.projectClasses}`,
  delete: (id: string) => `${routes.projectClasses}/${id}`,
  update: (id: string) => `${routes.projectClasses}/${id}`,
};

const dimensioningTypes = {
  read: () => `${routes.dimensioningTypes}`,
  readUnique: (id: string) => `${routes.dimensioningTypes}/${id}`,
  create: () => `${routes.dimensioningTypes}`,
  delete: (id: string) => `${routes.dimensioningTypes}/${id}`,
  update: (id: string) => `${routes.dimensioningTypes}/${id}`,
};

const payment = {
  createSubscription: () => routes.payment + '/create-subscription',
  cancelSubscription: (id: string) => routes.payment + `/cancel-subscription/${id}`,
  getCustomerData: (id: string) => routes.payment + `/customer/${id}`,
};

const subscriptions = {
  confirmSubscription: (id: string) => `${routes.subscriptions}/confirm/${id}`,
};

const items = {
  read: (page: number, limit: number) => `${routes.items}/findAll/${page}/${limit}`,
  readUnique: (id: string) => `${routes.items}/${id}`,
  create: () => `${routes.items}`,
  delete: (id: string) => `${routes.items}/${id}`,
  update: (id: string) => `${routes.items}/${id}`,
  readGroups: () => `${routes.items}/getGroups`,
};

const itemLists = {
  read: (projectId: string) => `${routes.itemList}/find-all/${projectId}`,
  readUnique: (id: string) => `${routes.itemList}/${id}`,
  create: () => `${routes.itemList}`,
  delete: (id: string) => `${routes.itemList}/${id}`,
  update: (id: string) => `${routes.itemList}/${id}`,
  duplicate: (id: string) => `${routes.itemList}/duplicate/${id}`,

  reportListItems: () => `${routes.itemList}/report-list/items`,
  reportListBundles: () => `${routes.itemList}/report-list/bundles`,
  reportListGeneral: () => `${routes.itemList}/report-list/general`,
  reportListGroups: () => `${routes.itemList}/report-list/groups`,

  readItemGroups: () => `${routes.itemList}/getItemGroups`,
  readItemBundles: () => `${routes.itemList}/getBundleGroups`,
};

const itemBundles = {
  read: (page: number, limit: number) => `${routes.itemBundles}/findAll/${page}/${limit}`,
  readItems: (id: string) => `${routes.itemBundles}/get-items/${id}`,
  readUnique: (id: string) => `${routes.itemBundles}/${id}`,
};

export const apiRoutes = {
  auth,
  users,
  projects,
  spdas,
  spdasTypes,
  structures,
  viewCuts,

  calcs,
  parallelCalcs,

  norms,
  projectClasses,
  dimensioningTypes,
  plans,
  payment,
  subscriptions,

  items,
  itemLists,
  itemBundles,
};
