import { blue, red, grey } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { fReport } from 'src/utils/formatNumber';

interface coordinates {
  x: number;
  y: number;
}

interface Props {
  id?: string;
  hcAux: number; // altura do centro do círculo
  gap: number; // distancia entre os spdas

  R: number; // raio da esfera rolante
  r: number; // raio da area classificada

  dAe: number; // distância da área classificada

  h: number; // altura do spda

  d1: number; // distância da estrutura
  h1: number; // altura da estrutura
  l1: number; // largura da estrutura

  dp: number;
  dpc: number;
  hcpj: number;
}

export const ParallelCalcChart = ({
  id,
  hcAux,
  gap,
  R,
  r,
  dAe,
  h,
  d1,
  h1,
  l1,
  dp,
  dpc,
  hcpj,
}: Props) => {
  let curveLineData: coordinates[] = [];
  let curveLineDataRight: coordinates[] = [];
  let curveLineDataLeft: coordinates[] = [];
  let eaCurveLineData: coordinates[] = [];

  let y;
  for (let x = 0; x <= gap + 0.1; x += 0.1) {
    y = -Math.sqrt(R ** 2 - (x - gap / 2) ** 2) + hcAux;
    curveLineData.push({ x: x, y: y });
  }

  let y1;
  let xInicial = gap;
  for (let x = xInicial; true; x += 0.1) {
    y1 = -Math.sqrt(R ** 2 - (x - gap - dp - dpc) ** 2) + hcpj;
    if (curveLineDataRight.length >= 1 && curveLineDataRight[curveLineDataRight.length - 1].y < y1)
      break;
    curveLineDataRight.push({ x: x + gap - xInicial, y: y1 });
  }

  let y2;
  let xInicial2 = 0;
  for (let x = xInicial2; true; x -= 0.1) {
    y2 = -Math.sqrt(R ** 2 - (Math.abs(x) - dp - dpc) ** 2) + hcpj;
    if (curveLineDataLeft.length >= 1 && curveLineDataLeft[curveLineDataLeft.length - 1].y < y2)
      break;
    curveLineDataLeft.push({ x: x - xInicial2, y: y2 });
  }

  for (let x = dAe - r; x <= dAe + r && r; x += 0.01) {
    y = +Math.sqrt(r ** 2 - (x - dAe) ** 2) + h1;
    eaCurveLineData.push({ x: x, y: y });

    y = -Math.sqrt(r ** 2 - (x - dAe) ** 2) + h1;
    eaCurveLineData.push({ x: x, y: y });
  }

  let structureData: coordinates[] = [];
  structureData.push({ x: d1, y: h1 }, { x: l1 + d1, y: h1 });

  let leftSpdaData: coordinates[] = [];
  leftSpdaData.push({ x: 0, y: h }, { x: 0, y: 0 });

  let rightSpdaData: coordinates[] = [];
  rightSpdaData.push({ x: gap, y: h }, { x: gap, y: 0 });

  const [series, setSeries] = useState<any>([
    {
      name: 'R (centro)',
      type: 'line',
      data: curveLineData,
      color: blue[400],
    },
    {
      name: 'R (direita)',
      type: 'line',
      data: curveLineDataRight,
      color: blue[400],
    },
    {
      name: 'R (esquerda)',
      type: 'line',
      data: curveLineDataLeft,
      color: blue[400],
    },
    {
      name: 'Estrutura',
      type: 'area',
      data: structureData,
      color: '#497EB2',
    },
    {
      name: 'SPDA (esquerda)',
      type: 'line',
      data: leftSpdaData,
      color: grey[900],
    },
    {
      name: 'SPDA (direita)',
      type: 'line',
      data: rightSpdaData,
      color: grey[900],
    },
    eaCurveLineData.length > 0
      ? {
          name: 'r',
          type: 'line',
          data: eaCurveLineData,
          color: red[400],
        }
      : {},
  ]);

  const [options, setOptions] = useState<any>({
    markers: {
      size: [0, 0, 0, 0],
    },
    chart: {
      id: id || 'parallelChart',
      zoom: { enabled: false },
      toolbar: { show: false },
      ...(id
        ? {
            animations: {
              enabled: false,
              easing: 'easeinout',
              speed: 99999,
              animateGradually: {
                enabled: false,
                delay: 0,
              },
              dynamicAnimation: {
                enabled: false,
                speed: 9999,
              },
            },
          }
        : {}),
    },
    xaxis: {
      labels: {
        floating: false,
        show: true,
        formatter(value: any) {
          return value ? fReport(value, 1).replace(',0', '') : 0;
        },
      },
      axisTicks: {
        show: true,
      },
      floating: false,
      tickAmount: gap + 4,
      min: curveLineDataLeft[curveLineDataLeft.length - 1].x,
      max: curveLineDataRight[curveLineDataRight.length - 1].x,
      title: {
        text: 'd(m)',
        align: 'left',
        style: {
          fontSize: '16px',
          fontWeight: '400',
        },
      },
      decimalsInFloat: 1,
    },
    yaxis: {
      min: 0,
      max: 2 * h,
      floating: false,
      title: {
        floating: false,
        text: 'h(m)',
        style: {
          fontSize: '16px',
          fontWeight: '400',
        },
      },
      labels: {
        floating: false,
        show: true,
        formatter(value: any) {
          return value ? fReport(value, 1).replace(',0', '') : 0;
        },
      },
    },
    stroke: {
      width: 3,
    },
    tooltip: {
      enabled: true,
      y: {
        title: {
          formatter: function (seriesName: any) {
            if (seriesName === 'R') {
              return 'R (h):';
            }
            return seriesName;
          },
        },
        formatter: function (value: any, { series, seriesIndex, dataPointIndex, w }: any) {
          return value ? fReport(value) : 0;
        },
      },
      x: {
        formatter: function (value: any, { series, seriesIndex, dataPointIndex, w }: any) {
          if (seriesIndex === 0) {
            return `R (d): ${value ? fReport(value) : 0}`;
          }
          return value ? value.toFixed(2) : 0;
        },
      },
    },
    legend: {
      onItemClick: {
        toggleDataSeries: false,
      },
    },
  });

  useEffect(() => {
    setSeries([
      {
        name: 'R (centro)',
        type: 'line',
        data: curveLineData,
        color: blue[400],
      },
      {
        name: 'R (direita)',
        type: 'line',
        data: curveLineDataRight,
        color: blue[400],
      },
      {
        name: 'R (esquerda)',
        type: 'line',
        data: curveLineDataLeft,
        color: blue[400],
      },
      {
        name: 'Estrutura',
        type: 'area',
        data: structureData,
        color: '#497EB2',
      },
      {
        name: 'SPDA (esquerda)',
        type: 'line',
        data: leftSpdaData,
        color: grey[900],
      },
      {
        name: 'SPDA (direita)',
        type: 'line',
        data: rightSpdaData,
        color: grey[900],
      },
      eaCurveLineData.length > 0
        ? {
            name: 'r',
            type: 'line',
            data: eaCurveLineData,
            color: red[400],
          }
        : {},
    ]);

    setOptions({
      markers: {
        size: [0, 0, 0, 0],
      },
      chart: {
        id: id || 'parallelChart',
        zoom: { enabled: false },
        toolbar: { show: false },
      },
      xaxis: {
        labels: {
          floating: false,
          show: true,
          formatter(value: any) {
            return value ? fReport(value, 1).replace(',0', '') : 0;
          },
        },
        axisTicks: {
          show: true,
        },
        floating: false,
        tickAmount: gap + 2,
        min: curveLineDataLeft[curveLineDataLeft.length - 1].x,
        max: curveLineDataRight[curveLineDataRight.length - 1].x,
        title: {
          text: 'd(m)',
          align: 'left',
          style: {
            fontSize: '16px',
            fontWeight: '400',
          },
        },
        decimalsInFloat: 1,
      },
      yaxis: {
        min: 0,
        max: 2 * h,
        floating: false,
        title: {
          floating: false,
          text: 'h(m)',
          style: {
            fontSize: '16px',
            fontWeight: '400',
          },
        },
        labels: {
          floating: false,
          show: true,
          formatter(value: any) {
            return value ? fReport(value, 1).replace(',0', '') : 0;
          },
        },
      },
      stroke: {
        width: 3,
      },
      tooltip: {
        enabled: true,
        y: {
          title: {
            formatter: function (seriesName: any) {
              if (seriesName === 'R') {
                return 'R (h):';
              }
              return seriesName;
            },
          },
          formatter: function (value: any, { series, seriesIndex, dataPointIndex, w }: any) {
            return value ? fReport(value) : 0;
          },
        },
        x: {
          formatter: function (value: any, { series, seriesIndex, dataPointIndex, w }: any) {
            if (seriesIndex === 0) {
              return `R (d): ${value ? fReport(value) : 0}`;
            }
            return value ? value.toFixed(2) : 0;
          },
        },
      },
      legend: {
        onItemClick: {
          toggleDataSeries: false,
        },
      },
    });
  }, [R, h, h1, l1, d1, hcAux, r, dAe]);

  return <ReactApexChart options={options} series={series} />;
};
