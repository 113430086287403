import Router from './routes';
import ThemeProvider from './theme';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import MotionLazyContainer from './components/animate/MotionLazyContainer';

// ----------------------------------------------------------------------

import 'moment/locale/pt-br';
import moment from 'moment';
import ErrorBoundary from './pages/error/errorBoundary';
moment.locale('pt-br');

export default function App() {
  return (
    <ErrorBoundary>
      <MotionLazyContainer>
        <ThemeProvider>
          <NotistackProvider>
            <ProgressBarStyle />
            <ChartStyle />
            <ScrollToTop />
            <Router />
          </NotistackProvider>
        </ThemeProvider>
      </MotionLazyContainer>
    </ErrorBoundary>
  );
}
