import React from 'react';
import { Alert, AlertTitle, Box, Button, Card, Divider, Modal, Typography } from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import { axiosInstance } from 'src/utils/axios';
import { apiRoutes } from 'src/routes/api';
import { useSnackbar } from 'notistack';

// type StateProps = {
//   payment_modal?: boolean;
//   no_sub_modal?: boolean;
// };

type ModalCancelSubscriptionProps = {
  open: boolean;
  handleCloseModal: () => void;
};

export const ModalDeactivateAccount = ({
  open,
  handleCloseModal,
}: ModalCancelSubscriptionProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const { logout, user } = useAuth();

  const cancelSubscription = async () => {
    try {
      await axiosInstance.delete(apiRoutes.users.deactivate(user?.id));
      enqueueSnackbar('Conta desativada com sucesso com sucesso!', { variant: 'success' });
      logout();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }

    handleCloseModal();
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Card
        sx={{
          minHeight: '400px',
          maxWidth: '700px',
          padding: '15px',
        }}
      >
        <Typography sx={{ marginBottom: '10px' }}>Cancelamento de Assinatura</Typography>
        <Divider />
        <Alert severity="warning" sx={{ marginTop: '10px' }}>
          <AlertTitle>Atenção</AlertTitle>
          Tem certeza que deseja desativar a sua conta?
        </Alert>
        <Card
          sx={{
            backgroundColor: '#F4F6F8',
            border: '1px solid rgba(145, 158, 171, 0.24)',
            padding: '10px',
            marginTop: '10px',
          }}
        >
          {`Apenas ao cancelar antes de completar 7 dias da confirmação de cadastro, você não receberá a cobrança em seu cartão de crédito. Em caso de dúvidas, entre em contato com
          o nosso suporte ao cliente.`}
        </Card>
        <Typography variant="h5" sx={{ textAlign: 'center', marginTop: '10px' }}>
          {`Você confirma a exclusão da sua conta?`}
        </Typography>
        <Typography sx={{ textAlign: 'center', marginTop: '10px' }}>
          {`Se confirmada a ação, seu plano de assinatura será cancelado e você não poderá mais
          acessar as principais funcionalidades do sistema. Todos os seus dados de usuário, de
          projetos e cálculos serão removidos da nossa base de dados. Você não retomará mais esses
          dados e somente poderá acessar novamente a plataforma ao realizar um novo cadastro.`}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '20px',
            marginTop: '15px',
          }}
        >
          <Button variant="outlined" onClick={handleCloseModal}>
            NÃO
          </Button>
          <Button variant="contained" onClick={cancelSubscription}>
            SIM
          </Button>
        </Box>
      </Card>
    </Modal>
  );
};
